import React from 'react';
import { Outlet } from 'react-router-dom';

const ReportPage = () => {
  return (
    <div className='report-page'>
      <Outlet />      
    </div>
  );
};

export default ReportPage;