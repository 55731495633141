import { useState } from 'react'
import {Form , Field} from 'react-final-form'
import { useParams , useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {useClient} from '../../hooks/client'
import StatusPill from '../../components/status/StatusPill'
import DateTimeBox from '../../components/date/DateTimeBox'
import OutlineButton from '../../components/button/OutlineButton'
import {useNotification, useConfirmation}  from '../../hooks';
import { updateClient, deleteClient } from '../../states/client'

const ProfileInput = ({...input}) => {
  return <input {...input} className='profile-input' />
}

const ClientProfileContainer = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { confirmAction } = useConfirmation()
  const {showNotificationError, showNotificationSuccess} = useNotification()
  const client = useClient(params.clientId)
  const [editMode, setEditMode] = useState(false)
  
  const handleUpdate = async (values) => {
    setEditMode(false)
    try {
      await dispatch(updateClient({...values})).unwrap()
      showNotificationSuccess('Update Success', '')
    } catch ( updateError ) {
      console.error(updateError)
      showNotificationError('Update Error', '')
    }
  }

  const handleDelete = async () => {
    console.log('Deleting ...')
    const proceed = await confirmAction('Are you sure you want to delete Admin user ?')
    if(proceed){
       try {
        await dispatch(deleteClient(params.clientId)).unwrap()
        showNotificationSuccess('Client Deleted', '')
        navigate('/clients')
      } catch( error ) {
        console.error(error)
        showNotificationError('Delete Error', '')
      }
    }
  }

  return (
    <div className="client-profile-container">
      <div className="client-profile">
        <div className="client-profile__header">
        
          <div className="client-profile__header--left">
            <h2>Profile</h2>        
            <div className="actions">
              <span onClick={e => setEditMode(true)} >
                <ion-icon name="create-outline"></ion-icon>
              </span>
              <span onClick={handleDelete} >
                <ion-icon name="trash-outline"></ion-icon>
              </span> 
            </div>  
          </div>
        
          <div className="client-profile__header--right">
            <StatusPill success={true} label="active" />
            <DateTimeBox />
          </div>
        </div>
        
        <div className="client-profile__header-title">
          contact information
        </div>

        <Form onSubmit={handleUpdate} initialValues={client}>
        { ({handleSubmit, values}) => (
          <div className="client-profile__content">
            <div className="client-profile__content-title">
  
              <Field  name="name" > 
              {({input}) => <ProfileInput {...input} disabled={!editMode} /> } 
              </Field> 
            
            </div>
          
            <div className="client-profile__content-contact">
              <div className="client-profile__content-contact-item">
                <label>company</label>
                <Field name="name">
                { ({input}) => <ProfileInput {...input} disabled={!editMode}  /> }
                </Field>
              </div>
              <div className="client-profile__content-contact-item">
                <label>phone</label>
                <Field name="phone">
                { ({input}) => <ProfileInput {...input} disabled={!editMode}  /> }
                </Field>
               
              </div>
              <div className="client-profile__content-contact-item">
                <label>Representative</label>
                <Field name="rep_name">
                { ({input}) => <ProfileInput {...input} disabled={!editMode}  /> }
                </Field>
              </div>
              <div className="client-profile__content-contact-item">
                <label>email</label>
                <Field name="email">
                { ({input}) => <ProfileInput {...input} disabled={!editMode}  /> }
                </Field>
              </div>
            </div>

            <div className="client-profile__content-note">
              <label>NOTES</label>
              <Field name="note">
                { ({input}) => <textarea {...input} disabled={!editMode}  /> }
              </Field>
            </div>
            
            {/* <pre> {JSON.stringify(values, 0, 2)} </pre> */}
            {editMode && <OutlineButton onClick={handleSubmit} >save</OutlineButton>}
        </div>
        )}
        </Form>
      </div>
    </div>
  )
}

export default ClientProfileContainer