import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react'
import classNames from 'classnames'

const DropzoneField = ({ input, extraCallback, image, className, ...rest }) => {
  
  const onDrop = useCallback(acceptedFiles => {
    console.log('Accepted Files: ', acceptedFiles)
    input.onChange(acceptedFiles)
    extraCallback?.(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop, ...rest })
 
  return (
    <div {...getRootProps({ className: classNames(className, "dropzone-field") })}>
      <input {...getInputProps()} />
      {isDragActive ? <p>Drop the files here </p> : <p>Drag n drop some files here or click</p>}
      {acceptedFiles.length > 0 ? ( 
        <div className="dropzonen-field__image-wrapper">
          <img src={URL.createObjectURL(acceptedFiles[0])} className='dropzone-field__image' />
        </div>

        ) : image && ( 
          <div className="dropzone-field__image-wrapper">
            <img src={image} alt="current-image" className='dropzone-field__image' /> 
          </div>
        )
      }
    </div>
  )
}

export default DropzoneField