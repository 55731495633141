import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from '../../utils/axios'

export const fetchPhotoBooth = createAsyncThunk('photobooth/fetch', async (data, thunkAPI) => {
    const result = await axiosInstance.get('booth/')
    return result.data
  }
)

export const createPhotoBooth = createAsyncThunk('photobooth/create', async (data, thunkAPI) => {
  const { formData, onUploadProgress} = data
    const result = await axiosInstance.post('booth/', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress
    })
    return result.data
  }
)


export const updatePhotoBooth = createAsyncThunk('photobooth/update', async (data) => {
    const { boothId, ...bootData } = data
    const result = await axiosInstance.patch(`booth/${boothId}/`, bootData)
    return result.data
  }
)

export const deletePhotoBooth = createAsyncThunk('photobooth/delete', async (data) => {
    const { boothId } = data
    await axiosInstance.delete(`booth/${boothId}/`)
    return boothId
  }
)

const boothSlice = createSlice({
  name: 'photobooth',
  initialState: { photobooths: null },
  reducers: {
    setBoothsStores(state, action) {

    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPhotoBooth.fulfilled, (state, action) => {
      state.photobooths = action.payload
    }).addCase(createPhotoBooth.fulfilled, (state, action) => {
      state.photobooths = state.photobooths.concat(action.payload)
    }).addCase(deletePhotoBooth.fulfilled, (state, action) => {
      state.photobooths = state.photobooths.filter(p => p.id !== action.payload)
    }).addCase(updatePhotoBooth.fulfilled, (state, action) => {
      state.photobooths = state.photobooths.map(p => p.id === action.payload.id ? action.payload : p)
    })
  }
})

// export const { } = boothSlice.actions
export default boothSlice.reducer