import ReportItem from './ReportItem';

const TopReportBox = ({data, title}) => {
  return  (
    <div className="top-report-box">
      <div className="top-report-box__header">
        {title}
      </div>
      <div className="top-report-box__content">
        {data.map( item => (
         <ReportItem {...item} />
         ))}
      </div>
    </div>
  )
}

export default TopReportBox