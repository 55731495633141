import { Provider } from "react-redux";
import "./sass/main.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRoutes from "./routes";
import NotificationProvider from "./contexts/notification";
import ConfirmationProvider from "./contexts/confirmation";
import MapProvider from "./contexts/map";
import store from "./states";

function App() {
  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {/* <MantineProvider> */}
        <NotificationProvider>
          <ConfirmationProvider>
            <MapProvider>
              <AppRoutes />
            </MapProvider>
          </ConfirmationProvider>
        </NotificationProvider>
        {/* </MantineProvider> */}
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
