import {Form, Field} from 'react-final-form'
import Button from '../button'

const ClientForm = ({handleSubmit}) => {
  return (
    <div className="client-form">
      <Form 
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors = {}
          Array(...['name', 'rep_name', 'phone', 'email', 'note']).forEach(key => {
            if ( ! (key in values) ) 
              errors[key] = `${key} is Required`;
          });
          console.log('validation errors: ', errors)
          return errors
        }}
      >
        {({handleSubmit, values, submitting}) => (
          <form onSubmit={handleSubmit}>
            <Field name="name">
              { ({input, meta}) => (
                <div className="client-form__group">
                  <input {...input} placeholder="Company Name" />
                  {meta.error && meta.touched && <span className="client-form__group-error">{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="rep_name">
              { ({input, meta}) => (
                <div className="client-form__group">
                  <input {...input} placeholder="Representative Full Name" />
                  {meta.error && meta.touched && <span className="client-form__group-error">{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="phone">
              { ({input, meta}) => (
                <div className="client-form__group">
                  <input {...input} placeholder="Phone" />
                  {meta.error && meta.touched && <span className="client-form__group-error">{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="email">
              { ({input, meta}) => (
                <div className="client-form__group">
                  <input {...input} placeholder="Email" type="email"/>
                  {meta.error && meta.touched && <span className="client-form__group-error">{meta.error}</span>}
                </div>
              )}
            </Field>
            <Field name="note">
              { ({input, meta}) => (
                <div className="client-form__group">
                  <textarea {...input} placeholder="Client note" />
                  {meta.error && meta.touched && <span className="client-form__group-error">{meta.error}</span>}
                </div>
              )}
            </Field>
            <Button loading={submitting} onClick={handleSubmit} icon="person-add-outline">Register</Button>
          </form>
        )}
      </Form>
    </div>
  )
}

export default ClientForm