import React from 'react';
import { useDispatch } from "react-redux"
import {createStore} from '../../states/store';
import StoreCreateForm from '../../components/store/StoreCreateForm';
import DateTimeBox from '../../components/date/DateTimeBox';
import {useNotification} from '../../hooks'
import { useNavigate } from 'react-router-dom';

const StoreCreateContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {showNotificationSuccess, showNotificationError} = useNotification()
  
  const handleStoreCreate = async (values) => {
    console.log('Creating store ... ', values)
    const {location: {address, state, country, city}, ...rest} = values
    const location = {address, country: country?.value, state: state?.value, city: city?.value}
    try {
      await dispatch(createStore({...rest, location})).unwrap()
      showNotificationSuccess('Store Created', "Store Created!")
      navigate('/stores/list/')
    } catch ( clientCreateError ) {
      console.error('Store Create Error: ', clientCreateError)
      showNotificationError('Create Error', 'Store Create Error')
    }
  }

  return (
    <div className='store-create-container'>
       <div className="store-create-container__header">
        <h2>New Store Registration</h2>
        <div className="store-create-container__header-inner">
          <span>contact information</span>
          <DateTimeBox />
        </div>
      </div>
      <div className="store-create-container__content">
        <StoreCreateForm handleSubmit={handleStoreCreate} />
      </div>
    </div>
  );
};

export default StoreCreateContainer ;