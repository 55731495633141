import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../utils/axios'

export const fetchStores = createAsyncThunk('store/fetchStores', async (data, thunkAPI) => {
    const result = await axiosInstance.get('stores/')
    return result.data
  }
)

export const createStore = createAsyncThunk('store/createStore', 
  async (storeData, thunkAPI) => {
    const result = await axiosInstance.post('stores/', storeData)
    return result.data
  }
)

export const deleteStore = createAsyncThunk('machine/deleteStores', 
  async (storeId, thunkAPI) => {
    await axiosInstance.delete(`stores/${storeId}/`)
    return storeId
  }
)

export const updateStore = createAsyncThunk('machine/updateStore', async (storeData, thunkAPI) => {
    const storeId = storeData.id
    const result = await axiosInstance.patch(`stores/${storeId}/`, storeData)
    return result.data
  }
)

const storeSlice = createSlice({
  name: 'store',
  initialState: { stores: null },
  reducers: {
    setStoresBooth(state, action) {
      state.stores = state.stores.map(store => action.payload.stores.includes(store.id) ? {...store, photobooth: action.payload.boothId} : store)
    },
    setStoresSTW(state, action) {
      state.stores = state.stores.map(store => action.payload.stores.includes(store.id) ? {...store, stw: action.payload.stwId} : store)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStores.fulfilled, (state, action) => {
      state.stores = action.payload
    })
    .addCase(createStore.fulfilled, (state, action) => {
      state.stores = state.stores.concat(action.payload)
    })
    .addCase(updateStore.fulfilled, (state, action) => {
      state.stores = state.stores.map(s => s.id === action.payload.id ? action.payload : s)
    })
    .addCase(deleteStore.fulfilled, (state, action) => {
      state.stores = state.stores.filter(s => s.id !== action.payload) 
    })
  }
})

export const  {setStoresBooth, setStoresSTW} = storeSlice.actions
export default storeSlice.reducer