import { Link } from "react-router-dom";

const StatDeviceItem = ({ device }) => {
  return (
    <Link to={`/stat/device/${device.id}`}>
      <div className="stat-machine">
        <span className="stat-machine__title">{device?.name}</span>
        <div className="blinker" />
      </div>
    </Link>
  );
};

export default StatDeviceItem;
