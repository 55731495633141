import React from 'react';
import { Outlet } from 'react-router-dom';

const DevicePage = () => {
  return (
    <div className='device-page'>
      <Outlet />      
    </div>
  );
};

export default DevicePage;