import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../components/button";
import DeviceChartOverView from "./components/device-chart-overview";
import { DeviceChartContainer } from "./components/device-charts";
import { MoreDetailView } from "./store-detail";
import { useDeviceStatMore } from "../../hooks/ai-stat";
import { useDevice } from "../../hooks/device";

const DeviceDetailView = ({ deviceId }) => {
  const todayString = new Date().toISOString().split("T")[0];
  const [currentDate, setCurrentDate] = useState(todayString);
  const data = useDeviceStatMore(deviceId, currentDate);

  return (
    <div className="store-more-detail">
      <div className="day-selector">
        <label htmlFor="select-detail-date">Select Date</label>
        <input
          type="date"
          id="select-detail-date"
          value={currentDate}
          onChange={(e) => setCurrentDate(e.target.value)}
        />
      </div>
      <MoreDetailView data={data} key={currentDate} />
    </div>
  );
};

const DeviceStatDetail = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const device = useDevice(deviceId);

  return (
    <div className="device-stat-detail">
      <h4 className="title">Device Chart Stat</h4>
      <DeviceChartOverView deviceId={deviceId} />

      <DeviceDetailView deviceId={deviceId} />

      <div className="device-stat-detail__chart">
        <DeviceChartContainer deviceId={deviceId} />
      </div>

      <div className="device-stat-detail__footer">
        <Button onClick={() => navigate(`/stat/store/${device.store}`)}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default DeviceStatDetail;
