import React from 'react';
import { useStoreDevices } from '../../hooks/device';

const PhotoBoothStoreDevices = ({store, onItemClick}) => {
  const devices = useStoreDevices(store?.id)
  console.log('devices: ', devices, ' store: ', store)
  return (
    <div className='photobooth-store-devices'>
      <div className="photo-config__devices-area__content">
        { devices.map( device => (
          <div className="device" key={device.id} onClick={e => onItemClick(device) }>
            <ion-icon name="desktop-outline"></ion-icon>
            <div className="device__title">{device.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoBoothStoreDevices;