import classNames from "classnames";

const Button = ({ children, type, onClick, loading = false, icon, disabled, style }) => {
  return (
    <button
      className={classNames({
        'button': true,
        [`button--${type}`]: !!type?.length,
        'button--loading': loading,
        'button--iconic': !!icon
      })}
      onClick={onClick}
      disabled={loading || disabled}
      style={style}
    >
      {icon && <span className="icon"><ion-icon name={icon}></ion-icon></span>}
      {loading && <div className="loader"></div>}
      <span>{children}</span>
    </button>
  )
}

export default Button;