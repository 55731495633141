import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { axiosInstance, axiosInstanceWithBlob } from '../../utils/axios';

export const fetchGallery = createAsyncThunk('gallery/fetch',
  async (data, thunkAPI) => {
    const response = await axiosInstance.get('gallery/')
    return response.data
  }
)

export const deleteImage = createAsyncThunk(
  'gallery/delete',
  async (imageId, thunkAPI) => {
    await axiosInstance.delete(`gallery/${imageId}`)
    return imageId
  }
)


export const deleteAllImage = createAsyncThunk(
  'gallery/deleteAll',
  async (clientId, thunkAPI) => {
    await axiosInstance.post(`gallery/${clientId}/delete/`)
    return clientId
  }
)

export const downloadImage = createAsyncThunk(
  'gallery/download',
  async (imageId, thunkAPI) => {
    const response = axiosInstanceWithBlob.get(`gallery/${imageId}/download`)
    console.log('response: ', response)
    return response
  }
)

const gallerySlice = createSlice({
  name: 'gallery',
  initialState: {
    images: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGallery.fulfilled, (state, action) => {
      state.images = action.payload
    })
    .addCase(deleteImage.fulfilled, (state, action) => {
      state.images = state.images.filter( i => i.id !== action.payload )
    })
  }
})

export default gallerySlice.reducer
