import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useStores } from "../../hooks/store";

const StatStoreItem = ({ store, onStoreClick, selected }) => {
  const { name, location } = store;
  return (
    <div
      className={`stat-store-item ${selected ? "selected" : ""}`}
      onClick={onStoreClick}
    >
      <h6 className="stat-store-item__title">{name}</h6>
      <span className="stat-store-item__address">
        {location
          ? `${location.address}, ${location.city}, ${location.state}, ${location.country}`
          : "N/A"}
      </span>
    </div>
  );
};

const StatStoreItems = ({ stores, onStoreClick, selected }) => {
  return (
    <div className="stat-page__sidebar-items">
      {stores.map((store) => (
        <StatStoreItem
          store={store}
          key={store.id}
          onStoreClick={() => onStoreClick(store)}
          selected={selected === store.id}
        />
      ))}
    </div>
  );
};

const StatStoreSearch = ({ onInputChange, input }) => {
  return (
    <div className="stat-store-search">
      <div className="stat-store-search__input">
        <input
          type="text"
          placeholder="Filter store ..."
          value={input}
          onChange={onInputChange}
        />
      </div>
    </div>
  );
};

const StatPageSidebar = () => {
  const navigate = useNavigate();
  const [filterInput, setFilterInput] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const stores = useStores();

  const _handleFilterInputChange = (event) => {
    setFilterInput(event.target.value);
  };

  const _handleStoreClick = (store) => {
    console.log("Selected store:", store);
    navigate(`/stat/store/${store.id}`);
    setSelectedStore(store.id);
  };

  const filteredStores =
    filterInput.length > 0
      ? stores.filter((store) =>
          store.name.toLowerCase().includes(filterInput.toLowerCase())
        )
      : stores;

  return (
    <div className="stat-page__sidebar">
      <StatStoreSearch
        onInputChange={_handleFilterInputChange}
        input={filterInput}
      />
      <StatStoreItems
        stores={filteredStores}
        onStoreClick={_handleStoreClick}
        selected={selectedStore}
      />
    </div>
  );
};

const StatPage = () => {
  return (
    <div className="stat-page">
      <div className="stat-page__header"></div>
      <div className="stat-page__main">
        <StatPageSidebar />
        <div className="stat-page-sub">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default StatPage;
