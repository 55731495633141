import React from 'react';
import {useState} from 'react'
import { useStores } from '../../hooks/store';
import OutlineButton from '../button/OutlineButton'
import TextInput from '../fields/TextInput';

const StorePickerItem = ({store, onClick}) => (
  <div
    className='store-item' 
    key={store.id}
    onClick={onClick}
  >{store.name}</div>
)

const StorePicker = ({excludes, handleCancel, handleSubmit}) => {
  const stores = useStores()
  const [searchFilter, setSearchFilter] = useState('')
  const [selectedStores, setSelectedStores] = useState([])

  const addStore = (store) => {
    setSelectedStores( pd => [...pd, store])
  }

  console.log('added store to list', selectedStores)

  
  return (
    <div className='store-picker'>
      <div className="store-picker__wrapper">
        <div className="store-picker__body">
          <div className="store-picker__header">
            <span className='store-picker__title'>choose store </span>
            <div className="store-picker__search-input">
              <TextInput 
                value={searchFilter} 
                onChange={e => setSearchFilter(e.target.value)}
                className="search-input" 
                placeholder="search store by name .. " />
            </div>
          </div>
          <div className="store-picker__content">
            <div className="store-items">
            { stores?.filter( store => !excludes?.includes(store.id))
              .filter( store => store?.name.toLowerCase().includes(searchFilter.toLowerCase()))
              .filter( store => !selectedStores.includes(store))
              .map( store => (
                <StorePickerItem onClick={e => addStore(store)} store={store}/> 
              ))}
            </div>
            { selectedStores?.length > 0 &&
              <div className="items-selections">
                <div className="items-selections__header">Selected stores</div>
                <div className="items-selections__content">
                  {selectedStores.map( store => <StorePickerItem store={store}/> )}
                </div>
              </div>
            }
          </div>

          <div className="store-picker__footer">
            <OutlineButton onClick={e => handleSubmit(selectedStores)}>Done</OutlineButton>
            <OutlineButton style={{borderColor: '#dc6c6c'}} onClick={handleCancel}>Cancel</OutlineButton>
          </div>

        </div>
      </div>
    </div>
  );
};

export default StorePicker;