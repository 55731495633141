import { useQuery } from "react-query";
import axiosInstance from "../utils/axios";

export const useDevicesQuery = (options) =>
  useQuery(
    ["devices"],
    async () => {
      const response = await axiosInstance.get("machines/");
      return response.data;
    },
    {
      ...options,
    }
  );
