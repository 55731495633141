import { useNavigate } from "react-router-dom"
import moment from 'moment'
const ClientCard = ({client}) => {
  const navigate = useNavigate()
  const {name, rep_name, email, registered} = client
  
  return (
    <div className="client-card">

      <div className="client-card__header">
        <div className="client-card__header-title">
         {name}
        </div>
        <div className="client-card__header-timestamp">
          <span>Started on</span>
          <span>{moment(registered).format('h:mm a | MM/DD/YY')}</span>
        </div>
      </div>

      <div className="client-card__representative">
        <span>Representative</span>
        <span>{rep_name ?? 'N/A'}</span>
      </div>

      <div className="client-card__email">
        <ion-icon name="mail-outline"></ion-icon>
        <span>{email}</span>
      </div>

      <div className="client-card__actions">
        <ul>
          <li onClick={e => navigate(`${client.id}/profile/`)}>
            <span><ion-icon name="person-outline"></ion-icon></span>
            <span>Profile</span>
          </li>
          <li onClick={e => navigate(`${client.id}/config/`)}>
            <span><ion-icon name="settings-outline"></ion-icon></span>
            <span>Config</span>
          </li>
          <li onClick={e => navigate(`${client.id}/stores/`)}>
            <span><ion-icon name="bag-outline"></ion-icon></span>
            <span>Stores</span>
          </li>
          <li onClick={e => navigate(`${client.id}/reports/survey/`)}>
            <span><ion-icon name="document-text-outline"></ion-icon></span>
            <span>Reports</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ClientCard