import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Form, Field } from "react-final-form";
import Button from "../button";
import SelectAdapter from "../select/SelectAdapter";
import { loadCountries, loadStates, loadCities } from "../../utils/location";

const StoreCreateForm = ({
  handleSubmit,
  initialValues = {},
  editMode = true,
}) => {
  const [countries, setCountries] = useState();
  const [selectedCountryISO, setSelectedCountryISO] = useState("");
  const [states, setStates] = useState();
  const [cities, setCities] = useState();

  const StoreField = ({ name, label }) => (
    <Field name={name}>
      {({ input, meta }) => (
        <div
          className={classNames("store-form__group field-normal", {
            "field-error": meta.error && meta.touched,
          })}
        >
          <label>{label}</label>
          <input {...input} disabled={!editMode} />
        </div>
      )}
    </Field>
  );

  const countryOptions = useMemo(
    () =>
      countries?.map((country) => ({
        label: country.name,
        value: country.name,
        iso: country.iso2,
      })),
    [countries]
  );

  const stateOptions = useMemo(
    () =>
      states?.map((state) => ({
        label: state.name,
        value: state.name,
        iso: state.iso2,
      })),
    [states]
  );

  const cityOptions = useMemo(
    () => cities?.map((city) => ({ label: city.name, value: city.name })),
    [cities]
  );

  useEffect(() => {
    async function _f() {
      const _countries = await loadCountries();
      setCountries(_countries);
    }
    _f();
  }, []);

  const onCountryChange = async (e, input) => {
    input.onChange(e);
    const _states = e?.iso ? await loadStates(e.iso) : [];
    console.log("loaded states", _states);
    setStates(_states);
    setSelectedCountryISO(e?.iso);
  };

  const onStateChange = async (e, input) => {
    input.onChange(e);
    const _cities = e?.iso ? await loadCities(selectedCountryISO, e.iso) : [];
    console.log("loaded cities: ", _cities);
    setCities(_cities);
  };

  return (
    <div className='store-form'>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors = {};
          Array("name", "rep_name", "phone", "email").forEach((key) => {
            if (!(key in values)) errors[key] = `${key} is Required`;
          });
          console.log("validation errors: ", errors);
          return errors;
        }}
      >
        {({ handleSubmit, values, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className='store-form__content'>
              <Field name='name'>
                {({ input, meta }) => (
                  <div
                    className={classNames("store-form__group field-normal", {
                      "field-error": meta.error && meta.touched,
                    })}
                  >
                    <label>Store name</label>
                    <input {...input} disabled={!editMode} />
                  </div>
                )}
              </Field>
              <div className=''></div>
              <Field name='location.address'>
                {({ input, meta }) => (
                  <div
                    className={classNames("store-form__group field-normal", {
                      "field-error": meta.error && meta.touched,
                    })}
                  >
                    <label>Address</label>
                    <input {...input} disabled={!editMode} />
                  </div>
                )}
              </Field>

              <Field name='location.country'>
                {({ input, meta, ...rest }) => (
                  <SelectAdapter
                    className={classNames("store-form__group field-normal", {
                      "field-error": meta.error && meta.touched,
                    })}
                    placeholder='select country ..'
                    options={countryOptions}
                    label='Country'
                    {...rest}
                    {...input}
                    onChange={(e) => onCountryChange(e, input)}
                    isDisabled={!editMode}
                  />
                )}
              </Field>

              <Field name='location.state'>
                {({ input, meta, ...rest }) => (
                  <SelectAdapter
                    className={classNames("store-form__group field-normal", {
                      "field-error": meta.error && meta.touched,
                    })}
                    options={stateOptions}
                    label='State'
                    {...rest}
                    {...input}
                    onChange={(e) => onStateChange(e, input)}
                    isDisabled={!editMode}
                  />
                )}
              </Field>

              <Field name='location.city'>
                {({ input, meta, ...rest }) => (
                  <SelectAdapter
                    className={classNames("store-form__group field-normal", {
                      "field-error": meta.error && meta.touched,
                    })}
                    options={cityOptions}
                    label='City'
                    {...rest}
                    {...input}
                    isDisabled={!editMode}
                  />
                )}
              </Field>

              <StoreField label='store representative ' name='rep_name' />
              <StoreField label='email address ' name='email' />
              <StoreField label='mobile number' name='mobile' />
              <StoreField label='store phone' name='phone' />

              {/*  The opening and closing times */}
              <Field name='weekday_opening'>
                {({ input, meta }) => (
                  <div
                    className={classNames("store-form__group field-small", {
                      "field-error": meta.error && meta.touched,
                    })}
                  >
                    <label>Weekday Opening</label>
                    <input {...input} disabled={!editMode} type='time' />
                  </div>
                )}
              </Field>
              <Field name='weekday_closing'>
                {({ input, meta }) => (
                  <div
                    className={classNames("store-form__group field-small", {
                      "field-error": meta.error && meta.touched,
                    })}
                  >
                    <label>Weekday Closing</label>
                    <input {...input} disabled={!editMode} type='time' />
                  </div>
                )}
              </Field>
              <Field name='weekend_opening'>
                {({ input, meta }) => (
                  <div
                    className={classNames("store-form__group field-small", {
                      "field-error": meta.error && meta.touched,
                    })}
                  >
                    <label>Weekend Opening</label>
                    <input {...input} disabled={!editMode} type='time' />
                  </div>
                )}
              </Field>
              <Field name='weekend_closing'>
                {({ input, meta }) => (
                  <div
                    className={classNames("store-form__group field-small", {
                      "field-error": meta.error && meta.touched,
                    })}
                  >
                    <label>Weekend Closing</label>
                    <input {...input} disabled={!editMode} type='time' />
                  </div>
                )}
              </Field>
              {/* End of the opening and closing time  */}
            </div>
            {initialValues ? (
              editMode && ( // indicates we're on the edit page but we're not editing
                <Button loading={submitting} disabled={submitting || pristine}>
                  {" "}
                  Save{" "}
                </Button>
              )
            ) : (
              <Button loading={submitting} disabled={submitting || pristine}>
                {" "}
                Submit{" "}
              </Button>
            )}
          </form>
        )}
      </Form>
    </div>
  );
};

export default StoreCreateForm;
