import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import SearchFilter from '../../components/SearchFilter';
import SortBox from '../../components/SortBox';
import StoreCard from '../../components/store/StoreCard';
import Button from '../../components/button';
import {useStores} from '../../hooks/store';

const StoreListContainer = () => {
  const navigate = useNavigate()
  const stores = useStores()
  const [filterValue, setFilterValue] = useState('')
  const handleFilter = (e) => setFilterValue(e.target.value)

  return (
    <div className='store-list-container'>
      <div className="store-list-container__header">
        <SearchFilter handleOnChange={handleFilter} value={filterValue} />
        <Button onClick={e => navigate('/stores/create/')} icon="add-outline"> ADD </Button>
      </div>

      <div className="store-list-container__header-sort">
        <SortBox>Vabe ID </SortBox>
        <SortBox>Registration Date </SortBox>
      </div>

      <div className="store-list-container__content"> 
      { stores && 
        stores.filter(store => store.name.toLowerCase().includes(filterValue.toLowerCase()))
          .map( store =>  <StoreCard key={store.id} store={store} /> )
      }
      </div>

    </div>
  );
};

export default StoreListContainer 