import { useQuery } from "react-query";
import { makeRequest } from "../utils/axios";

export const useStoreStat = (storeId, interval = "day", date = "") => {
  console.log("useStoreStat: ", date);
  const { data, isLoading } = useQuery(
    ["ai-stat", "store", storeId, interval, date],
    () => {
      if (!storeId) return;
      return makeRequest(
        `ai/report/store/${storeId}/data/?interval=${interval}&date=${date}`
      );
    }
  );

  return { data: data?.["data"], loading: isLoading };
};

export const useDeviceStat = (deviceId, interval = "day", date = "") => {
  const { data, isLoading } = useQuery(
    ["ai-stat", "device", deviceId, date],
    () => {
      if (!deviceId) return;
      return makeRequest(
        `ai/report/device/${deviceId}/?interval=${interval}&date=${date}`
      );
    }
  );

  return { data: data?.["data"], loading: isLoading };
};

export const useStoreStatOverview = (storeId) => {
  const { data, isLoading } = useQuery(["ai-stat", "overview", storeId], () => {
    if (!storeId) return;
    return makeRequest(`ai/report/store/${storeId}/overview/`);
  });

  if (isLoading) return;
  return data;
};

export const useDeviceStatOverview = (deviceId) => {
  const { data, isLoading } = useQuery(
    ["ai-stat", "overview", "device", deviceId],
    () => {
      if (!deviceId) return;
      return makeRequest(`ai/report/device/${deviceId}/overview/`);
    }
  );

  if (isLoading) return;
  return data;
};

export const useStoreStatMore = (
  storeId,
  date_string = "",
  date_range = "",
  timeframe = ""
) => {
  // timeframe, date_string, date_range . can only use one of the 3
  // timeframe is either today, week, month, all
  const { data, isLoading } = useQuery(
    ["ai-stat", "more", "store", storeId, date_string, date_range, timeframe],
    () => {
      if (!storeId) return;
      return makeRequest(
        `ai/report/store/${storeId}/more-detail/?date=${date_string}&date_range=${date_range}&timeframe=${timeframe}`
      );
    }
  );
  if (isLoading) return;
  return data;
};

export const useDeviceStatMore = (deviceId, date_string = "") => {
  const { data, isLoading } = useQuery(
    ["ai-stat", "more", "device", deviceId, date_string],
    () => {
      if (!deviceId) return;
      return makeRequest(
        `ai/report/device/${deviceId}/detail/?date=${date_string}`
      );
    }
  );
  if (isLoading) return;
  return data;
};
