import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserAddHeader from './UserAddHeader';
import {Form, Field} from 'react-final-form';
import OutlineButton from '../../components/button/OutlineButton';
import InputField from './components/InputField';
import CustomSelect from './components/CustomSelect'
import {useClients} from '../../hooks/client';
import { createClientUser } from '../../states/auth';
import {useNotification} from '../../hooks';

const UserClientAddContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const clients = useClients()
  const { showNotificationError, showNotificationSuccess} = useNotification()
  
  const clientOptions = useMemo( () => (
    clients.map(client => ({value: client.id, label: client.name}) ) 
  ), [clients] )

  const handleSubmit = async (values) => {
    try {
      const clientUser = await dispatch(createClientUser({
        ...values, 
        client: values?.client.value
      })).unwrap()
      showNotificationSuccess('Client User Created')
      navigate(`/users/client/${clientUser.id}`)
    } catch ( userCreateError ) {
      console.error(userCreateError)
      showNotificationError('Creation Failed')
    }
  }
  
  return (
    <div className="user-add-container">
      
      <div className="user-add-container__header">
        <UserAddHeader  title="Client" />
      </div>

      <div className="user-add-container__content">
        <Form onSubmit={handleSubmit}>
          {({handleSubmit, values}) => (
            <>
            <div className="contact-section">
              <InputField label="username" name="username" />
              <InputField label="password" name="password" />
              <InputField label="First Name" name="first_name"/>
              <InputField  label="Last Name" name="last_name"/>
              <InputField  label="Email Address" name="email"/>
              <InputField  label="Cell Number" placeholder="Number with availability required" name="phone" />
            </div>
          
            <div className="criteria-section">
              <div className="criteria-section__header">
                <span>CRITERIAS</span>
              </div>

              <div className="criteria-section__content">
                <Field name="client">{ ({input, ...rest}) => (
                  <CustomSelect 
                    options={clientOptions} 
                    label="Client Management" 
                    {...rest} 
                    {...input}
                  />
                  )}
                </Field>
              </div>

            </div>

            <div className="actions-section">
              <OutlineButton icon="save-outline" onClick={handleSubmit}>Create Client</OutlineButton>
            </div>
            </>
            )}
          </Form>
      </div>
    </div>
  );
};

export default UserClientAddContainer;