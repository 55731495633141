import { useSelector } from "react-redux"
import {useClientVideos} from './video';
import { usePhotoBooths } from "./photobooth";
import {useSTWWheels} from './stw';

//store
export const useStores = () => {
  const stores = useSelector(state => state.store.stores)
  return stores
}

export const useStore = (storeId) => {
  const stores = useStores()
  return stores?.find(store => store.id === storeId)
}

/*
return all stores of the specified store Ids
*/
export const useStoresByIds = (storeIds) => {
  const stores = useStores()
  return storeIds && stores?.filter( store => storeIds.includes(store.id))
}

/*
  returns all stores that's assigned to the specified video 
----
*/


// export const useStoreBooth = (storeId) => {
//   const booths = usePhotoBooths()
//   return booths?.find(b => b.store === storeId)
// }

/*
  return all stores where the specified client has a video 
*/
export const useVideoClientStores = (clientId) => {
  const stores = useStores()
  const videos = useClientVideos(clientId)
  const videosStoreIds = videos?.reduce((acc, v) => acc.concat(...v.stores), []).filter(vId => vId !== null)
  return stores?.filter(s => videosStoreIds?.includes(s.id))
}

export const useClientPhotoBoothStores = (clientId) => {
  const stores = useStores()
  const photobooths = usePhotoBooths()
  const storeIds = photobooths?.filter(photobooth => photobooth.owner === clientId)
    .reduce((acc, photobooth) => [...acc, ...photobooth?.stores] , [])
  return stores?.filter(s => storeIds?.includes(s.id))
}

export const useClientWheelStores = (clientId) => {
  const stores = useStores()
  const wheels = useSTWWheels()
  const wheelsIds = wheels?.filter(wheel => wheel.owner === clientId)
    .reduce(( acc, wheel) => [...acc, ...wheel?.stores], [] )
  return stores?.filter(s => wheelsIds?.includes(s.id))
}