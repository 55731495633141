import { useClientPhotoBooths, usePhotoBoothsByIds } from "../../hooks/photobooth"
import classNames from "classnames"

const PhotoBoothTableList = ({clientId, onItemClick, onItemDelete, selectedId, loading}) => {
  const booths = useClientPhotoBooths(clientId)

  return booths ? (
    <div className="booth-table-list">
      <div className="booth-table-list__content">
      {booths?.map( booth => (
        <div 
          key={booth.id} 
          className={classNames("booth-table-list__content-item", {"active": selectedId === booth.id})}
        >                  
          <div className="item__title" onClick={e => onItemClick(booth)}>
            <ion-icon name="camera-outline"></ion-icon>
            <span> {booth.name} </span>
          </div>
          <span onClick={e => onItemDelete(booth.id)}  className='item__delete'>
            <ion-icon name="trash"></ion-icon>
          </span>
        </div>
      ))}
      </div>
    </div>
  )  : <h4>No PhotoBooths available</h4>
}

export default PhotoBoothTableList