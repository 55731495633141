import { useNavigate } from 'react-router-dom';
import DashBox from '../../components/dashbox';
import MenuButton from './components/MenuButton';
import {useAdminUsers, useClientUsers} from '../../hooks/auth';

const UserDashboard = () => {
  const navigate = useNavigate()
  const navigateToList = () => navigate('list/')
  const adminUsers = useAdminUsers()
  const clientUsers = useClientUsers()

  return (
    <div className='user-dashboard'>
      
      <div className="user-dashboard__header">
        <MenuButton />
      </div>

      <div className="user-dashboard__content">
        <DashBox value={adminUsers?.length} label="Admins" onClick={navigateToList }/>      
        <DashBox value={clientUsers?.length} label="Client Users" onClick={navigateToList}/>      
      </div>

    </div>
  );
};

export default UserDashboard;