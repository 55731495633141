import {Field} from 'react-final-form';

const InputField = ({label, placeholder, name}) => (
  <Field name={name}>
    {({input}) => (
      <div className="input-field">
        <label>{label}</label>
        <input {...input} placeholder={placeholder} />
      </div>
    )}
  </Field>
)

export default InputField;