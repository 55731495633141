import React from 'react';
import StatusPill from '../../components/status/StatusPill';
import DateTimeBox from '../../components/date/DateTimeBox';

const UserAddHeader = ({title, secondary}) => {
  return (
    <div className='user-add-header'>
      
      <div className="user-add-header--left">
        <span className="title">{title}</span>
        <span className="label">{secondary ??  'new user' }</span>
      </div>

      <div className="user-add-header--right">
        <StatusPill label="active" success={true} />
        <DateTimeBox />
      </div>

    </div>
  );
};

export default UserAddHeader;