import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuButton = () => {
  return (
    <div className="menu-btn">
      <div className="menu-btn__content">
        <span><ion-icon name="add-outline"></ion-icon></span>
        <span>New User</span>
      </div>
      <div className="menu-btn__drop-down">
        <NavLink to="/users/add/admin/">Admin</NavLink>
        <NavLink to="/users/add/client/">Client</NavLink>
      </div>
    </div>
  );
};

export default MenuButton;
