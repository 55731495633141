import { Outlet } from 'react-router-dom';

const ClientPage = () => {
  return (
    <div className="client-page">
      <Outlet />
    </div>
  )
}

export default ClientPage