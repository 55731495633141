import {Route, Routes} from 'react-router-dom'
import ClientPage from '../pages/client'
import {
  ClientListContainer, 
  ClientCreateContainer,
  ClientProfileContainer,
  ClientConfigContainer,
  ClientConfigFormContainer,
  ClientConfigVideoContainer,
  ClientConfigPhotoContainer,
  ClientConfigGameContainer,
  ClientStoresContainer,
  ClientReportContainer,
  ClientReportSurveyContainer,
  ClientReportLeadContainer,
  ClientReportWinnerContainer,
  ClientReportScanContainer,
  ClientReportGalleryContainer,
  ClientConfigPrizeContainer,
} from '../containers/client'

const ClientRoutes = () => (
  <Routes>
    <Route path="" element={<ClientPage />} >
      <Route index element={<ClientListContainer /> } />
      <Route path="create/" element={<ClientCreateContainer /> } />
      <Route path=":clientId/profile/" element={<ClientProfileContainer /> } />
      <Route path=":clientId/stores/" element={<ClientStoresContainer /> } />
      <Route path=":clientId/config/" element={<ClientConfigContainer /> }>
        <Route path="forms/" element={<ClientConfigFormContainer />} />
        <Route path="videoads/" element={<ClientConfigVideoContainer />} />
        <Route path="game/" element={<ClientConfigGameContainer />} />
        <Route path="photobooth/" element={<ClientConfigPhotoContainer />} />
        <Route path="prizes/" element={<ClientConfigPrizeContainer />} />
      </Route>
      <Route path=":clientId/reports/" element={<ClientReportContainer /> }>
        <Route path="survey/" element={<ClientReportSurveyContainer />} />
        <Route path="leads/" element={<ClientReportLeadContainer />} />
        <Route path="winners/" element={<ClientReportWinnerContainer />} />
        <Route path="scans/" element={<ClientReportScanContainer />} />
        <Route path="gallery/" element={<ClientReportGalleryContainer />} />
      </Route>
    </Route>
  </Routes>
)

export default ClientRoutes;