import React from 'react';
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query';
import { makeRequest } from '../../utils/axios';
import SortBox from '../../components/SortBox';
import TopReportBox from '../../components/report/TopReportBox';

const ProgressItem = ({ label, value }) => {
  return (
    <div className="progress-item">
      <div className="progress-item__title">{label}</div>
      <div className="progress-item__content">
        <progress max={500} value={value}>{value}</progress>
        <span>{value}</span>
      </div>
    </div>
  )
}

const ClientReportSurveyContainer = () => {
  const params = useParams()
  const { data } = useQuery('survey', () => makeRequest(`report/${params.clientId}/leads/survey/`))
  return (
    <div className='report-survey-container'>
      <div className="header">
        {/* <div className="header--left">
          <SortBox>Date</SortBox>
          <SortBox>City</SortBox>
          <SortBox>State</SortBox>
        </div>
        <div className="header--right"></div> */}
      </div>
      <div className="content">
        {data ? Object.entries(data).map(([label, values]) => {
          const t_maxi = Math.max(...Object.entries(values).map(([key, val]) => val));
          const maxi = t_maxi + 100
          const newData = Object.entries(values).map(([key, val]) => ({ primary: `${key} Star`, value: val, max: maxi }));
          return <TopReportBox title={label} data={newData} key={label} />
        }) : <p>Loading</p>}
      </div>
    </div>
  );
};

export default ClientReportSurveyContainer;