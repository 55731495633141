import { NavLink } from "react-router-dom";

const SidebarItem = ({title, icon, to="/"}) => {
  return (
    <li className="sidebar__item">
      <NavLink to={to}>
        <span className="sidebar__item-icon">
          <ion-icon name={icon}></ion-icon>
        </span>
        <span className="sidebar__item-title">{title}</span>
      </NavLink>
    </li>
  )
}

export default SidebarItem;