import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "../../utils/axios"

export const loginUser = createAsyncThunk('auth/login', 
  async (data, thunkAPI) => {
    const response = await axiosInstance.post('auth/token/', data)
    return response.data 
  }
)

export const fetchUser = createAsyncThunk('auth/fetchUser', 
  async (data, thunkAPI) => {
    const response = await axiosInstance.get('auth/user/status/', data)
    return response.data
  }
)

export const fetchAdminUsers = createAsyncThunk('auth/fetchAdminUsers', 
  async (data, thunkAPI) => {
    const response = await axiosInstance.get('auth/user/admins/')
    return response.data
  }
)

export const fetchAdminUser = createAsyncThunk('auth/fetchAdminUser', 
  async (internalUserId, thunkAPI) => {
    const response = await axiosInstance.get(`auth/user/admins/${internalUserId}/internal/`)
    console.log('internal admin user: ', response.data)
    return response.data
  }
)

export const updateAdminUser = createAsyncThunk('auth/updateAdminUser', 
  async ({userId, ...data}) => {
    const response = await axiosInstance.patch(`auth/user/admins/${userId}/`, data)
    return response.data
  }
)

export const fetchAdminPermissions = createAsyncThunk('auth/fetchAdminPermissions',
  async (data) => {
    const response = await axiosInstance.get(`auth/user/admins/permissions/`)
    return response.data
  }
)

export const fetchAdminPermission = createAsyncThunk('auth/fetchAdminPermission',
  async (userId) => {
    const response = await axiosInstance.get(`auth/user/admins/${userId}/permissions/`)
    return response.data
  }
)

export const fetchClientUsers = createAsyncThunk('auth/fetchClientUsers', 
  async (data, thunkAPI) => {
    const response = await axiosInstance.get('auth/user/clients/', data)
    console.log('client users: ', response.data)
    return response.data
  }
)

export const createUser = createAsyncThunk('auth/createUser', 
  async (data) => {
    const response = await axiosInstance.post('auth/users/', data)
    return response.data
  }
)

export const createAdminUser = createAsyncThunk('auth/createAdminUser', 
  async (data, {dispatch}) => {
    const response = await axiosInstance.post('auth/user/admins/', data)
    return response.data
  }
)

export const createClientUser = createAsyncThunk('auth/createClientUser', 
  async (data, {dispatch}) => {
    const response = await axiosInstance.post('auth/user/clients/', data)
    return response.data
  }
)

export const updateClientUser = createAsyncThunk('auth/updateClientUser', 
  async ({userId, ...data}) => {
    const response = await axiosInstance.patch(`auth/user/clients/${userId}/`, data)
    return response.data
  }
)

export const deleteClientUser = createAsyncThunk('auth/deleteClientUser', 
  async ({userId}) => {
    await axiosInstance.delete(`auth/user/clients/${userId}/`);
    return userId
  }
)

export const deleteAdminUser = createAsyncThunk('auth/deleteAdminUser', 
  async ({userId}) => {
    console.log('user id: ', userId)
    await axiosInstance.delete(`auth/user/admins/${userId}/`);
    return userId
  }
)


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoggedIn: false,
    loginStatus: '', 
    adminUsers: null,
    clientUsers: null,
    adminPermissions: null,
  },
  reducers: {
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoggedIn = true
      state.loginStatus = 'LOGGEDIN'
    }).addCase(loginUser.pending, (state, action) => {
        state.loginStatus = 'PENDING'
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loginStatus = 'REJECTED'
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(fetchAdminUsers.fulfilled, (state, action) => {
        state.adminUsers = action.payload
      })
      .addCase(fetchClientUsers.fulfilled, (state, action) => {
        state.clientUsers = action.payload
      })
      .addCase(createAdminUser.fulfilled, (state, action) => {
        state.adminUsers = state.adminUsers.concat(action.payload)
      })
      .addCase(createClientUser.fulfilled, (state, action) => {
        state.clientUsers = state.clientUsers.concat(action.payload)
      })
      .addCase(fetchAdminPermissions.fulfilled, (state, action) => {
        state.adminPermissions = action.payload
      })
      .addCase(updateAdminUser.fulfilled, (state, action) => {
        state.adminUsers = state.adminUsers.map(u => 
          action.payload.id === u.id ? action.payload : u )
      })
      .addCase(deleteClientUser.fulfilled, (state, action) => {
        state.clientUsers = state.clientUsers.filter( u => action.payload != u.id )
      })
      .addCase(deleteAdminUser.fulfilled, (state, action) => {
        console.log('reducer payload: ', action.payload)
        state.adminUsers = state.adminUsers.filter( u => action.payload != u.id)
      })
  }
})

export const { setLoggedIn } = authSlice.actions
export default authSlice.reducer