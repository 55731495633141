const DashBox = ({label, value, secondary, onClick}) => {
  return (
    <div className="dashbox" onClick={onClick}>
      <div className="dashbox__label">
        <span className="primary">{label}</span>
        {secondary && <span className="secondary">{secondary}</span>}
      </div>
      <div className="dashbox__value">{value}</div>
    </div>
  )
}

export default DashBox