
import { createContext, useState } from "react";

export const MapContext = createContext(null);

function MapProvider({ children }) {
  const [selectedCountries, setSelectedCountries] = useState([]);

  const clearHighlightedCountries = () => {
    setSelectedCountries([]);
  }

  const isCountryHighlighted = (countryName) => {
    return selectedCountries.includes(countryName)
  }

  const toggleCountry = (countryName) => {
    setSelectedCountries(prevCountries => (
      prevCountries.includes(countryName) ?
        prevCountries.filter(p => p !== countryName) :
        [...prevCountries, countryName]
    ))
  }

  const highlightCountry = (countryName) => {
    setSelectedCountries(prevCountries =>
      prevCountries.includes(countryName) ?
        prevCountries : [...prevCountries, countryName]
    )
  }

  const highlightCountries = (countriesName) => {
    setSelectedCountries(prevCountries => (
      prevCountries.concat(countriesName.filter(i => !prevCountries.includes(i))))
    )
  }

  return (
    <MapContext.Provider value={{
      isCountryHighlighted,
      toggleCountry,
      highlightCountries,
      highlightCountry,
      selectedCountries,
      clearHighlightedCountries
    }}>
      {children}
    </MapContext.Provider>
  )
}

export default MapProvider;
