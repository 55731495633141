import { useSelector } from "react-redux"

// video
export const useVideos = () => {
  return useSelector(state => state.video.videos)
}

/*
pass in list of video Ids, hook returns the video object of those Ids  
*/
export const useClientVideos = (clientId) => {
  const videos = useVideos()
  return videos?.filter(v => v.owner === clientId)
}

/*
  return videos object of the passed in Ids
*/
export const useVideosByIds = (videoIds) => {
  const videos = useVideos()
  return videos?.filter(video => videoIds.includes(video.id))
}

/*
return the videos object of the passed in store Id
*/
export const useStoreVideos = (storeId) => {
  const videos = useVideos()
  return videos?.filter(video => video.stores.includes(storeId)) || []
}

/* 
  hook returns an objeck containing the video ids and their video object 
*/
export const useVideosKeys = (videosIds) => {
  const videos = useVideos()
  return videos?.filter(v => videosIds.includes(v.id)).reduce((acc, cv) => ({ ...cv, [cv.id]: cv }), {})
}