import classNames from "classnames"

const NotificationBox = ({title, message, type}) => {
  return (
    <div className={classNames("notification-box", `notification-box--${type}`)}>
      <div className="notification-box__icon">
        { type === 'success' ? 
          <ion-icon name="checkmark-circle-outline"></ion-icon>:
          <ion-icon name="close-outline"></ion-icon>
         }  
      </div>
      <div className="notification-box__title">
        {title}
      </div>
    </div>
  )
}

export default NotificationBox