import { Outlet } from "react-router-dom"
import Sidebar from '../../components/sidebar'

const OuterWrapper = (props) => {
  return (
    <div className='outer-wrapper'>
      <Sidebar />
      <div className="inner-wrapper">
        <div className="inner-wrapper__content">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default OuterWrapper