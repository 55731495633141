import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from '../../utils/axios'

export const createClient = createAsyncThunk(
  'client/createClient', 
  async (clientData, thunkAPI) => {
    const result = await axiosInstance.post('clients/', clientData)
    return result.data
  }
)

export const fetchClients = createAsyncThunk(
  'client/fetchClients', 
  async (data, thunkAPI) => {
    const result = await axiosInstance.get('clients/')
    return result.data
  }
)

export const updateClient = createAsyncThunk(
  'client/updateClient', 
  async (clientData, thunkAPI) => {
    console.log('clientData: ', clientData)
    const clientId = clientData.id
    const result = await axiosInstance.patch(`clients/${clientId}/`, clientData)
    return result.data
  }
)

export const deleteClient = createAsyncThunk(
  'client/deleteClient',
  async (clientId, thunkAPI) => {
    await axiosInstance.delete(`clients/${clientId}/`)
    return clientId
  }
)

export const suspendClient = createAsyncThunk(
  'client/suspendClient', 
  async (clientId, thunkAPI) => {
    const result = await axiosInstance.post(`clients/${clientId}/suspend/`)
    return result.data
  }
)

export const unSuspendClient = createAsyncThunk(
  'client/unsuspendClient', 
  async (clientId, thunkAPI) => {
    const result = await axiosInstance.post(`clients/${clientId}/unsuspend/`)
    return result.data
  }
)

export const updateClientFormData = createAsyncThunk(
  'client/updateFormData', 
  async (data, thunkAPI) => {
    const {formId, values} = data
    const result = await axiosInstance.patch(`forms/${formId}/`, values)
    return result.data
  }
)


export const updateClientFormImageData = createAsyncThunk(
  'client/updateFormData', 
  async (data, thunkAPI) => {
    const {formId, formData} = data
    const result = await axiosInstance.patch(`forms/${formId}/images/`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    return result.data
  }
)

export const updateClientPrizeData = createAsyncThunk(
  'client/updatePrizeData', 
  async (data, thunkAPI) => {
    const {prizeId, values} = data
    console.log('prize update data: ', values)
    const result = await axiosInstance.patch(`prizes/${prizeId}/`, values)
    return result.data
  }
)

export const updateClientPrizeDataImage = createAsyncThunk(
  'client/updatePrizeDataImage', 
  async (data, thunkAPI) => {
    const {prizeId, formData} = data
    const result = await axiosInstance.patch(`prizes/${prizeId}/images/`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    return result.data
  }
)

export const refreshClientFormDataQR = createAsyncThunk(
  'client/refreshFormDataQR', 
  async (data, thunkAPI) => {
    const { clientId } = data
    const result = await axiosInstance.post(`clients/${clientId}/form/qr/refresh/`)
    return result.data
  }
)

const clientSlice = createSlice({
  name: 'client',
  initialState: { clients: null },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.clients = action.payload
    })
    .addCase(createClient.fulfilled, (state, action) => {
      state.clients = state.clients.concat(action.payload)
    })
    .addCase(updateClient.fulfilled, (state, action) => {
      state.clients = state.clients.map(c => c.id === action.payload.id ? action.payload : c)
    })
    .addCase(deleteClient.fulfilled, (state, action) => {
      state.clients = state.clients.filter(c => c.id !== action.payload)
    })
    .addCase(suspendClient.fulfilled, (state, action) => {
      state.clients = state.clients.map(c => c.id === action.payload.id ? { ...c, suspended: action.payload.suspended } : c)
    })
    .addCase(unSuspendClient.fulfilled, (state, action) => {
      state.clients = state.clients.map(c => c.id === action.payload.id ? { ...c, suspended: action.payload.suspended } : c)
    })
    .addCase(updateClientFormData.fulfilled, (state, action) => {
      state.clients = state.clients.map(c => c.id === action.payload.owner ? { ...c, form_data: action.payload } : c)
    })
    .addCase(updateClientPrizeData.fulfilled, (state, action) => {
      state.clients = state.clients.map(c => {
        if(c.id === action.payload.owner) {
          if (action.payload.prize_type === 1)
            return { ...c, winner_prize_data: action.payload }
          else if (action.payload.prize_type === 2)
            return { ...c, survey_prize_data: action.payload }
        } else {
          return c
        }
      })
    })
    .addCase(refreshClientFormDataQR.fulfilled, (state, action) => {
      state.clients = state.clients.map(c => c.id === action.payload.id ? { ...c, form_data: { ...c.form_data, survey_qr: action.payload.url } } : c)
    })
  }
})

// export const { } = clientSlice.actions
export default clientSlice.reducer