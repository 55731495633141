import { useDispatch } from "react-redux"
import ClientForm from "../../components/forms/ClientForm"
import DateTimeBox from '../../components/date/DateTimeBox';
import {useNotification} from '../../hooks'
import {createClient} from '../../states/client'
import { useNavigate } from "react-router-dom";

const ClientCreateContainer = (values) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {showNotificationSuccess, showNotificationError} = useNotification()
  const handleClientCreate = async (values) => {
    console.log('Creating Store ... ', values)
    try {
      await dispatch(createClient({...values})).unwrap()
      showNotificationSuccess('Success', "Client Created!")
      navigate('/clients/')
    } catch ( clientCreateError ) {
      console.error('Client Create Error: ', clientCreateError)
      showNotificationError('Error', 'Client Create Error')
    }
  }
  return (
    <div className="client-create-container">
      <div className="client-create-container__header">
        <h2>New Client Registration</h2>
        <div className="client-create-container__header-inner">
          <span>contact information</span>
          <DateTimeBox />
        </div>
      </div>
      <div className="client-create-container__content">
        <ClientForm handleSubmit={handleClientCreate} />
      </div>
    </div>
  )
}

export default ClientCreateContainer