import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import SortBox from '../../components/SortBox'
import DashBox from '../../components/dashbox';
import { endpoint, makeRequest } from '../../utils/axios';
import { API_SERVER } from '../../config';
import { useConfirmation, useNotification } from '../../hooks';
import { deleteImage, deleteAllImage } from '../../states/gallery'
import Button from '../../components/button';

const GalleryBox = ({ item, onClick }) => {
  const { image } = item
  return (
    <div className="gallery-box" onClick={e => onClick(item)}>
      <img src={image.slice(0, 4) === 'http' ? image : `${API_SERVER.slice(0, -5)}${image}`} />
    </div>
  )
}

const GalleryViewer = ({ images, currentImage, onOutsideClick, handleImageDelete }) => {
  const { image, id: imageId } = currentImage
  const imageUrl = image.slice(0, 4) === 'http' ? image : `${API_SERVER.slice(0, -5)}${image}`
  const viewerRef = useRef()
  const handleOutsideClick = (e) => {
    // only run outside click if the overlay is clicked and not the content
    if (e.target === viewerRef.current) onOutsideClick();
  }

  return (
    <div ref={viewerRef} className="gallery-viewer" onClick={handleOutsideClick}>
      <div className="gallery-viewer__content" style={{width: "100%", maxWidth: "700px"}}  >
        <div className="gallery-viewer__image-box" style={{position: "relative"}} >
         <img src={imageUrl} style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}} />
        </div>
        <div className="gallery-viewer__action-box">
          <span onClick={e => handleImageDelete(currentImage)}><ion-icon name="trash-outline"></ion-icon></span>
          <span>
            <a href={endpoint(`gallery/${imageId}/download/`)} >
              <ion-icon name="download-outline"></ion-icon>
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

const ClientReportGalleryContainer = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const { confirmAction } = useConfirmation()
  const { showNotificationError, showNotificationSuccess } = useNotification()
  const [offset, setOffset] = useState(0);
  const { data, refetch } = useQuery(['gallery', offset], fetchGalleryQueryFunction);

  function fetchGalleryQueryFunction({ queryKey }) {
    return makeRequest(`report/${params.clientId}/gallery/?offset=${queryKey[1]}`)
  }

  const [galleryOpened, setGalleryOpened] = useState(false)
  const [initialImage, setInitialImage] = useState()

  const incrementOffset = () => {
    setOffset(prevOffset => prevOffset + 25);
  }

  const decrementOffset = () => {
    setOffset(prevOffset => prevOffset - 25);
  }

  const handleImageClick = (image) => {
    setGalleryOpened(true)
    setInitialImage(image)
  }

  const handleImageDelete = async (image) => {
    const { id: imageId } = image
    const proceed = await confirmAction('Are you sure you want to delete image ?')
    if (proceed) {
      try {
        await dispatch(deleteImage(imageId)).unwrap()
        showNotificationSuccess('Deleted !')
        setGalleryOpened(false)
        refetch()
      } catch (error) {
        showNotificationError('Error')
        console.error(error)
      }
    }
  }

  const handleDeleteAll = async () => {
    console.log('deleting all images .. ');
    const proceed = await confirmAction('Are you sure you want to delete all images in the gallery ?')
    if (proceed) {
      try {
        await dispatch(deleteAllImage(params.clientId)).unwrap()
        showNotificationSuccess('Deleted !')
        refetch()
      } catch (e) {
        showNotificationError('Error')
      }
    }
  }

  return (
    <div className='report-gallery-container'>
      <div className="report-gallery-container__header">
        <div className="report-gallery-container__header-left">
          {/* <SortBox>Date</SortBox>
          <SortBox>City</SortBox>
          <SortBox>State</SortBox> */}
        </div>
        <div className="report-gallery-container__header-right">
          <div className="header-btn">
            <span onClick={handleDeleteAll}>Delete All <ion-icon name="trash-outline"></ion-icon> </span>
          </div>
          <div className="header-btn">
            <a href={endpoint(`report/${params.clientId}/gallery/download-all`)}>
              <span>Download All</span>
              <span><ion-icon name="download-outline"></ion-icon></span></a>
          </div>
        </div>
      </div>
      <div className="report-gallery-container__content">
        {galleryOpened && (
          <GalleryViewer
            currentImage={initialImage}
            images={data?.recents}
            onOutsideClick={() => setGalleryOpened(false)}
            handleImageDelete={handleImageDelete}
          />
        )}

        <DashBox label="photos" value={data?.total} secondary="Total" />
        {data?.recents?.map(item => (
          <GalleryBox
            key={item.id}
            item={item}
            onClick={item => handleImageClick(item)}
          />
        ))}
      </div>
      <div className="" style={{ marginTop: '20px', display: 'flex', gap: '5px' }}>
        <Button onClick={decrementOffset} disabled={offset === 0}>Back</Button>
        <Button onClick={incrementOffset}>Next</Button>
      </div>
    </div>
  );
};

export default ClientReportGalleryContainer;
