import {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { fetchAdminPermissions, fetchAdminUsers, fetchClientUsers } from '../states/auth';

const UserPage = () => {
  const dispatch = useDispatch()
  
  const fetchAllUsers = async ( ) => {
    console.log('Fetching all users ... ')
    await dispatch(fetchAdminUsers()).unwrap()
    await dispatch(fetchClientUsers()).unwrap()
    await dispatch(fetchAdminPermissions()).unwrap()
  }
 
  useEffect( () => {
    fetchAllUsers()
  },[])

  return (
    <div className='user-page'>
      <Outlet />      
    </div>
  );
};

export default UserPage;