import { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { useLoggedIn } from "../../hooks/auth";
import { setLoggedIn } from "../../states/auth";
import { loginUserLocal } from "../../utils/auth";

const AuthWrapper = ({ children }) => {
  const isLoggedIn = useLoggedIn()
  const location = useLocation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function f() {
      if (!isLoggedIn) {
        const localLoggedIn = await loginUserLocal()
        if (localLoggedIn) dispatch(setLoggedIn(true))
      }
      setLoading(false)
    }
    f()
  }, [])

  return loading ? 
    <p>Loading</p> : 
      isLoggedIn ? 
        children : <Navigate to="/login" state={{ from: location }} />
}

export default AuthWrapper