import React from 'react';
import { useStore } from '../../hooks/store';

const ClientWinnerCard = ({winner}) => {
  const {first_name: firstName, last_name: lastName, email, created, store: storeId} = winner
  const dateStr = Intl.DateTimeFormat(navigator.language, {
    year: 'numeric', 
    month: 'numeric', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric'
  }).format(new Date(created))
  
  const store = useStore(storeId)


  return (
    <div className="winner-card">
      <div className="winner-card__header">
        <span className="title">{`${firstName} ${lastName}`}</span>
        <div className="contact">
          <span>{email}</span>
          {/* <span>510.235.1111</span> */}
        </div>
      </div>
      <div className="winner-card__store">
        <span>{store?.name ?? 'NA'}</span>
      </div>
      <div className="winner-card__footer">
        <span className="address">{store?.location.address ?? 'NA'}</span>
        <span className="timestamp">{dateStr}</span>
      </div>
    </div>
  );
};

export default ClientWinnerCard ;