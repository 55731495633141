import React from 'react';

const DashBox = ({labelPrimary, labelSecondary, labelValue, onClick}) => {
  return (
    <div className='store-dashbox' onClick={onClick}>
      <div className="label">
        <div className="label__primary">{labelPrimary}</div>
        <div className="label__secondary">{labelSecondary}</div>
      </div>
      <div className="value">{labelValue}</div>
    </div>
  );
};

export default DashBox;