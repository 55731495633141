import React from 'react';
import SortButton from './SortButton';

const SortBox = ({children, onClick}) => {
  return (
    <div className='sort-box' onClick={onClick}>
      <span>{children}</span>
      <SortButton />      
    </div>
  );
};

export default SortBox;