import {useSelector} from 'react-redux'

export const usePhotoBooths = () => {
  return useSelector(state => state.booth.photobooths)
}

export const usePhotoBooth = (boothId) => {
  const booths = usePhotoBooths()
  return booths?.find(booth => booth.id === boothId)
}

export const useClientPhotoBooths = (clientId) => {
  const booths = usePhotoBooths()
  return booths?.filter(booth => booth.owner === clientId)
}

export const useStorePhotoBooth = (storeId) => {
  const booths = usePhotoBooths()
  return booths?.find(booth => booth.store === storeId)
}

export const usePhotoBoothsByIds = (boothIds=[]) => {
  const booths = usePhotoBooths()
  return booths?.find( booth => boothIds.includes(booth.id))
}