import {useState} from 'react';
import SearchFilter from '../../components/SearchFilter';
import CheckBox from '../../components/fields/CheckBox';
import MenuButton from './components/MenuButton';
import UserCard from './components/UserCard';
import { useAdminUsers, useClientUsers } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';

const UserListContainer = () => {
  const navigate = useNavigate()
  const handleFilter = (e) => setFilterValue(e.target.value)
  const adminUsers = useAdminUsers()
  const clientUsers = useClientUsers()
  
  console.log('clientUsers: ', clientUsers)

  const [filterValue, setFilterValue] = useState('')
  const [adminChecked, setAdminChecked] = useState(true)
  const [clientChecked, setClientChecked] = useState(true)

  return (
    <div className='user-list-container'>
      <div className="user-list-container__header">
        <div className="user-search">
          <SearchFilter handleOnChange={handleFilter} value={filterValue} />
        </div>
        <MenuButton />
      </div>

      <div className="user-list-container__sort-filter">
        <CheckBox checked={adminChecked} onChange={e => setAdminChecked(p => !p)} >Admins</CheckBox>
        <CheckBox checked={clientChecked} onChange={e => setClientChecked(p => !p)}>Clients</CheckBox>
      </div>
      
      <div className="user-list-container__content">
        { adminUsers?.filter( e => adminChecked)
          // .filter(e => e.user.username.contains(filterValue))
          .map( user => ( 
            <UserCard 
              key={`a-${user.id}-1`} 
              user={{...user, isAdmin: true}} 
              onClick={e => navigate(`/users/admin/${user.id}`)} 
            /> 
          )).concat( clientUsers?.filter(e => clientChecked)
          .filter(e => e.user.username.includes(filterValue))
          .map( user => (
            <UserCard  
              key={`c-${user.id}-2`} 
              user={{...user, isClient: true}}
              onClick={e => navigate(`/users/client/${user.id}`)} 
            /> 
          )))
        }
      </div>
    </div>
  );
};

export default UserListContainer ;