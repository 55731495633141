import React from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import { useUser } from '../../hooks/auth';
import SortBox from '../../components/SortBox';

const ReportContainer = () => {
  const user = useUser()
  const {pathname} = useLocation()
  const reportType = pathname.slice(pathname.indexOf('/', 2)+1, pathname.length-1)

  // we might have to show the client report instead if client is type of user 
  //
  if(user?.is_clientuser) {
    return (
      <></>
    );
  }

  return (
    <div className='report-container'>
      <div className="report-container--inner">
        
        <div className="header">
          <span className='primary-text'>{reportType}</span>
          <span className='secondary-text'>general reports</span>
        </div>
        
        <div className="header-nav">
          <NavLink to="/reports/scans/" className="header-nav__item">scans</NavLink>
          <NavLink to="/reports/surveys/" className="header-nav__item">surveys</NavLink>
          <NavLink to="/reports/winners/" className="header-nav__item">winners</NavLink>
          <NavLink to="/reports/photos/" className="header-nav__item">photos</NavLink>
        </div>
{/*         
        <div className="header-sort">
          <SortBox>Client</SortBox>
          <SortBox>Date</SortBox>
          <SortBox>City</SortBox>
          <SortBox>State</SortBox>
        </div> */}

        <div className="content">
          <Outlet />
        </div>

      </div>
    </div>
  );
};

export default ReportContainer;