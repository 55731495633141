import React from 'react';
import { Outlet } from 'react-router-dom';
const UserAddContainer = () => {
  return (
    <div className='add-container'>
      <Outlet />      
    </div>
  );
};
 
export default UserAddContainer;