import { Route, Routes } from "react-router-dom";
import StatPage from "../containers/ai-stat";
import StoreStatDetail from "../containers/ai-stat/store-detail";
import DeviceStatDetail from "../containers/ai-stat/device-detail";
// stat
// stat/store/<storeID>
// stat/device/<deviceID>
const AiStatRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<StatPage />}>
        <Route index element={<p>Please select a store!</p>} />
        <Route path="store/:storeId" element={<StoreStatDetail />} />
        <Route path="device/:deviceId" element={<DeviceStatDetail />} />
      </Route>
    </Routes>
  );
};

export default AiStatRoutes;
