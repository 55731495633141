import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { makeRequest } from "../utils/axios";

export const useClients = () => {
  const clients = useSelector((state) => state.client.clients);
  return clients;
};

export const useClient = (clientId) => {
  const clients = useClients();
  return clients?.find((c) => c.id === clientId) || null;
};

// export const useDeviceClients = (machineId) => {
//   // return all clients that has a video on the machine
//   // get selected machine . store
//   const machine = useMachine(machineId)
//   const clients = useClients()
//   const videos = useStoreVideos(machine?.store) // get videos where store == machine store
//   const videosOwners = videos.reduce((acc, v) => acc.concat(v.owner), []) // get all owners of the videos  -> distinct
//   return clients.filter(c => videosOwners.includes(c.id))
// }

export const useClientsKeys = (clientsIds) => {
  const clients = useClients();
  return clients
    ?.filter((c) => clientsIds.includes(c.id))
    .reduce((acc, cv) => ({ ...cv, [cv.id]: cv }), {});
};

export const useAgeGuard = (clientId) => {
  const { data, isLoading } = useQuery(["ageguard", clientId], () => {
    if (!clientId) return;
    return makeRequest(`ageguard/${clientId}/check/`);
  });
  if (isLoading) return;
  return data.status;
};
