import { useState } from "react";
import { Form, Field } from "react-final-form";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { useAgeGuard, useClient } from "../../hooks/client";
import { useNotification } from "../../hooks";
import DropzoneField from "../../components/fields/DropzoneField";
import OutlineButton from "../../components/button/OutlineButton";
import CheckBox from "../../components/fields/CheckBox";

import {
  refreshClientFormDataQR,
  updateClientFormData,
  updateClientFormImageData,
} from "../../states/client";
import axiosInstance from "../../utils/axios";

const TabContainer = ({ data }) => {
  const [selected, setSelected] = useState("spanish");

  return (
    <div className="tab-container">
      <div className="tab-container__header">
        {Object.keys(data).map((t) => (
          <div
            className={`tab-container__header-item ${
              t === selected ? "selected" : ""
            }`}
            onClick={() => setSelected(t)}
          >
            {t}
          </div>
        ))}
      </div>
      <div className="tab-container__content">
        {selected === "" ? <p>Loading ...</p> : data[selected]}
      </div>
    </div>
  );
};

const ClientConfigFormContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const client = useClient(params.clientId);
  const { showNotificationSuccess, showNotificationError } = useNotification();
  const [lang, setLang] = useState(client?.form_data?.form_language);
  const ageGuarded = useAgeGuard(params.clientId);
  const queryClient = useQueryClient();

  console.log("age Guarded", ageGuarded);

  const toggleAgeGuard = () => {
    try {
      const data = axiosInstance.post(
        `ageguard/${params.clientId}/${ageGuarded ? "unset" : "set"}/`
      );
      queryClient.invalidateQueries({
        queryKey: ["ageguard", params.clientId],
      });
      showNotificationSuccess("Age Guard Updated", "");
    } catch (e) {
      console.error(e);
      showNotificationSuccess("Update Error", "");
    }
  };

  const handleFormUpdate = async (valuesRaw) => {
    const formId = client?.form_data?.id;

    if (formId === undefined) {
      showNotificationError("Outdated Client", "");
      return;
    }

    // remove the Survey QR , it starting to get messy below
    let { survey_qr, brand_image, banner_image, logo, ...values } = valuesRaw;

    const imageFormData = new FormData();

    if (Array.isArray(brand_image))
      imageFormData.append("brand_image", brand_image[0]);

    if (Array.isArray(logo)) imageFormData.append("logo", logo[0]);

    if (Array.isArray(banner_image))
      imageFormData.append("banner_image", banner_image[0]);

    try {
      console.log("updating .. form .. ");
      // process the images seperately
      if (
        imageFormData.has("logo") ||
        imageFormData.has("brand_image") ||
        imageFormData.has("banner_image")
      ) {
        console.log("updating images .. ");
        await dispatch(
          updateClientFormImageData({
            formId,
            formData: imageFormData,
          })
        ).unwrap();
      }
      await dispatch(
        updateClientFormData({
          formId,
          values: { ...values, form_language: lang },
        })
      ).unwrap();
      showNotificationSuccess("Succeed", "Update Success");
    } catch (formUpdateError) {
      console.error("Update Error: ", formUpdateError);
      showNotificationError("Error", formUpdateError?.message);
    }
  };

  const handleQRRefresh = async () => {
    try {
      await dispatch(
        refreshClientFormDataQR({ clientId: params.clientId })
      ).unwrap();
      showNotificationSuccess("Refresh success", "");
    } catch (refreshError) {
      showNotificationError("Refresh Error", "");
      console.error(refreshError);
    }
  };

  const initialValues = { ...client?.form_data };

  const LabelField = ({ name, placeholder, ...rest }) => {
    return (
      <Field name={name}>
        {({ input, meta }) => (
          <input {...input} placeholder={placeholder} {...rest} />
        )}
      </Field>
    );
  };

  const LabelForm = ({ suffix = "" }) => {
    return (
      <div className="form-labels">
        <LabelField
          name={`winner_label_start_title${suffix}`}
          placeholder="Start Title"
        />
        <LabelField
          name={`survey_label_check_title${suffix}`}
          placeholder="Check mark question"
        />
        <LabelField
          name={`survey_label_rate_title${suffix}`}
          placeholder="Rate title or questionnaire"
        />
        <LabelField
          name={`survey_label_rate_field1${suffix}`}
          placeholder="Rate Label 1"
        />
        <LabelField
          name={`survey_label_rate_field2${suffix}`}
          placeholder="Rate Label 2"
        />
        <LabelField
          name={`survey_label_rate_field3${suffix}`}
          placeholder="Rate Label 3"
        />
        <LabelField
          name={`survey_label_rate_field4${suffix}`}
          placeholder="Rate Label 4"
        />
        <LabelField
          name={`survey_label_check_option1${suffix}`}
          className="form-labels__option-A"
          placeholder="Option A"
        />
        <LabelField
          name={`survey_label_check_option2${suffix}`}
          className="form-labels__option-B"
          placeholder="Option B"
        />
        <LabelField
          name={`survey_label_comment_title${suffix}`}
          placeholder="Review Comment Title"
        />
      </div>
    );
  };
  return (
    <div className="form-config-container">
      <Form onSubmit={handleFormUpdate} initialValues={initialValues}>
        {({ handleSubmit, values, submitting }) => (
          <>
            <div className="config--pos-upper">
              <div className="form-config__left">
                <div className="form-config__language">
                  <span className="title">form language</span>
                  <div className="content">
                    <OutlineButton
                      active={lang === 1}
                      onClick={(e) => setLang(1)}
                    >
                      english
                    </OutlineButton>
                    <OutlineButton
                      active={lang === 2}
                      onClick={(e) => setLang(2)}
                    >
                      spanish
                    </OutlineButton>
                  </div>
                </div>

                <div className="form-config__qr">
                  <span className="title">Survey QR code</span>
                  <div className="content">
                    <img src={client?.form_data?.survey_qr} alt="" />
                    <div className="qr-actions">
                      <span>
                        <a href={client?.form_data?.survey_qr?.replace("/i/", "/d/")} download>
                          {" "}
                          Download{" "}
                        </a>
                      </span>
                      <span onClick={handleQRRefresh}>Refresh</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-config__right">
                <div className="form-config__logo">
                  <span className="title">Insert logo</span>
                  <div className="content">
                    <Field name="logo">
                      {({ input }) => (
                        <DropzoneField
                          input={input}
                          className="logo-image-dropzone"
                          image={client?.form_data?.logo}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className="form-config__brand-image">
                  <span className="title">Insert Brand Image</span>
                  <div className="content">
                    <Field name="brand_image">
                      {({ input }) => (
                        <DropzoneField
                          input={input}
                          className="brand-image-dropzone"
                          image={client?.form_data?.brand_image}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexFlow: "column wrap",
                margin: "2rem 0",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ margin: "2rem 2rem 2rem 0", fontSize: "2rem" }}>
                  Age Restriction 21+{" "}
                </span>
                <CheckBox checked={ageGuarded} onChange={toggleAgeGuard} />
              </div>
              <span>Enable for programs required by state or federal law</span>
            </div>
            <div className="config--pos-middle">
              <h4 className="title">survey form labels</h4>
              <div>
                {lang === 1 ? (
                  <LabelForm suffix=".english_text" />
                ) : (
                  <LabelForm suffix=".spanish_text" />
                )}
                {/* <TabContainer
                  data={{
                    english: <LabelForm suffix=".english_text" />,
                    spanish: <LabelForm suffix=".spanish_text" />,
                  }}
                /> */}
              </div>

              <div className="preview-sections">
                <a
                  href={`https://vabe.live/forms/survey?cid=${params.clientId}&preview`}
                  className="preview-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview Survey Form
                </a>
                <a
                  href={`https://vabe.live/forms/winner?cid=${params.clientId}&preview`}
                  className="preview-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview Winner Form
                </a>
              </div>
            </div>

            <div className="config--pos-lower">
              {/* <OutlineButton onClick={toggleAgeGuard}>
                {`${ageGuarded ? "Disable" : "Enable"}`} Age Guard
              </OutlineButton> */}
              <OutlineButton
                icon="save-outline"
                onClick={handleSubmit}
                loading={submitting}
              >
                Save Form
              </OutlineButton>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default ClientConfigFormContainer;
