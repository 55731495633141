import StatusPill from '../../components/status/StatusPill'
import DateTimeBox from '../../components/date/DateTimeBox'
import { useNavigate, Outlet, NavLink, useParams } from 'react-router-dom'
import { useClient } from '../../hooks/client'

const ClientConfigContainer = () => {
  const navigate = useNavigate()
  const params = useParams()
  const client = useClient(params.clientId)

  const menuRedirect = (location) => {
    navigate(location)
  }

  return (
    <div className="client-config-container">

      <div className="client-config">
        <div className="client-config__header">
        
          <div className="client-config__header--left">
            <h2>config settings</h2>          
          </div>
        
          <div className="client-config__header--right">
            <StatusPill success={true} label="active" />
            <DateTimeBox />
          </div>
        </div>

        <div className="client-config__header-title">
          BRANDING
        </div>

        <div className="menu-title--mobile">{client?.name}</div>
        <div className="client-config__header-menu">
          <div className="menu-title">{client?.name}</div>
          <div className="menu-content">
            
            <NavLink className="menu-item" to="forms">
              <span className="menu-item__icon">
                <ion-icon name="filter-outline"></ion-icon>
              </span>
              <span className="menu-item__title">Forms</span>
            </NavLink>

            <NavLink className="menu-item" to="videoads">
              <span className="menu-item__icon">
                <ion-icon name="play-outline"></ion-icon>
              </span>
              <span className="menu-item__title">Video Ads</span>
            </NavLink>
            
            <NavLink className="menu-item" to="photobooth">
              <span className="menu-item__icon">
                <ion-icon name="camera-outline"></ion-icon>
              </span>
              <span className="menu-item__title">Photobooth</span>
            </NavLink>
            
            <NavLink className="menu-item" to="game">
              <span className="menu-item__icon">
                <ion-icon name="pie-chart-outline"></ion-icon>
              </span>
              <span className="menu-item__title">Game</span>
            </NavLink>

            <NavLink className="menu-item" to="prizes">
              <span className="menu-item__icon">
                <ion-icon name="ribbon-outline"></ion-icon>
              </span>
              <span className="menu-item__title">Prizes</span>
            </NavLink>

          </div>
        </div>
        <div className="client-config__container">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default ClientConfigContainer