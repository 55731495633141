import React from 'react';

const CheckBox = ({children, ...props}) => {
  return (
    <label className="custom-checkbox"> 
      <input type="checkbox" {...props} /> 
      <div className="checkmark"></div>
      <span className='checklabel'>{children} </span>  
    </label>
  );
};

export default CheckBox;