import axios from "axios";

const API_KEY = "bkt4VDh4RlRQTW8weE10SkxFQTRVY0dzcTBVeUl1RklRQUpwYk5sNQ==";

const makeLocationRequest = async (url) => {
  const _config = {
    headers: { "X-CSCAPI-KEY": API_KEY },
  };
  const response = await axios.get(url, _config);
  return response.data;
};

const _sortData = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export const loadCountries = async () => {
  const countries = await makeLocationRequest(
    "https://api.countrystatecity.in/v1/countries"
  );
  countries.sort(_sortData); // sort alphabetically
  return countries;
};

export const loadStates = async (countryISO) => {
  const url = `https://api.countrystatecity.in/v1/countries/${countryISO}/states`;
  const states = await makeLocationRequest(url);
  states.sort(_sortData); // sort states alphabetically
  return states;
};

export const loadCities = async (countryISO, stateISO) => {
  const url = `https://api.countrystatecity.in/v1/countries/${countryISO}/states/${stateISO}/cities`;
  const cities = await makeLocationRequest(url);
  cities.sort(_sortData); // sort cities alphabetically
  return cities;
};
