import React from 'react';
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query';
import { makeRequest } from '../../utils/axios'; 
import ClientWinnerCard from '../../components/client/ClientWinnerCard';
import SortBox from '../../components/SortBox';
import { getClientWinnerReportUrl } from '../../utils/report';

const WinnerTotalCard = ({value}) => {
  return (
    <div className="winner-total-card">
      <div className="winner-total-card__left">
        <span className='primary'>Winners</span>
        <span className='secondary'>Total</span>
      </div>
      <div className="winner-total-card__right">
        <span>{value}</span>
      </div>
    </div>
  )
}
const ClientReportWinnerContainer = () => {
  const params = useParams()
  const {data} = useQuery('winner', () => makeRequest(`report/${params.clientId}/leads/winner/recent/`) )

  console.log('winner data : ',data)
  return (
    <div className='report-winner-container'>
      <div className="report-winner-container__header">
        <div className="report-winner-container__header-left">
          {/* <SortBox>Date</SortBox>
          <SortBox>City</SortBox>
          <SortBox>State</SortBox> */}
        </div>
        <div className="report-winner-container__header-right">
          <span><a href={getClientWinnerReportUrl(params.clientId)}>CSV | Download</a></span>
          <span><ion-icon name="document-outline"></ion-icon></span>
        </div>
      </div>
      <div className="report-winner-container__content">
        {data && data?.winners?.map(w => <ClientWinnerCard key={w.id} winner={w} /> )}
        <WinnerTotalCard value={data?.total}/>
      </div>
    </div>
  );
};

export default ClientReportWinnerContainer;