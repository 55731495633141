import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {Field, Form} from 'react-final-form';
import { useClient } from '../../hooks/client'
import OutlineButton from '../../components/button/OutlineButton';
import DropzoneField from '../../components/fields/DropzoneField';
import TextInput from '../../components/fields/TextInput';
import TextArea  from '../../components/fields/TextArea';
import { useNotification } from '../../hooks';
import {
  updateClientPrizeData,
  updateClientPrizeDataImage
} from '../../states/client';

const PrizeForm = ({onSubmit, client, prefix="winner"}) => {
  
  return client ? ( 
    <Form onSubmit={onSubmit} initialValues={client[`${prefix}_prize_data`]}>
    {({handleSubmit, values}) => (
    <>
    
    <div className="prize-config__field">
      <span className='prize-config__field-title'>{prefix} prize photo</span>
      <div className='prize-config__field-content'>
      <Field name="image">
      { ({input}) => (
        <DropzoneField 
          input={input} 
          className="prize-dropzone" 
          image={(client[`${prefix}_prize_data`]?.image?.slice(0, 4) === 'http' ? 
          '' : 'http://api.vabe.live') + client[`${prefix}_prize_data`]?.image }
        />
      )}
      </Field>
      </div>
    </div>

    <div className="prize-config__field">
      <div className="prize-config__field-title">
        Reward Item or Promo Code
      </div>
      <div className='prize-config__field-content'>
      <Field name="promo_code">{ ({input}) => <TextInput {...input} />  }</Field>
      </div>
    </div>
    
    <div className="prize-config__field">
      <div className="prize-config__field-title">
        Reward Description
      </div>
      <div className='prize-config__field-content'>
      <Field name="description.english_text">{ ({input}) => <TextArea  {...input} />  }</Field>
      </div>
    </div>
      
    <div className="prize-config__field">
      <div className="prize-config__field-title">
       URL or Social Media Link
      </div>
      <div className='prize-config__field-content'>
      <Field name="social_link">{ ({input}) => <TextInput {...input} />  }</Field>
      </div>
    </div>

    <div className="prize-config__field">
      <div className="prize-config__field-title">
        Social Media Hashtag
      </div>
      <div className='prize-config__field-content'>
        <Field name="hashtag">{ ({input}) => <TextInput {...input} />  }</Field>
      </div>
    </div>

    <OutlineButton icon="save" onClick={handleSubmit}>Save</OutlineButton>
    {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
    </>
  )}
  </Form>
  ) : (<p>Loading ... </p>)
}

const ClientConfigPrizeContainer = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const client = useClient(params.clientId)
  const { showNotificationSuccess, showNotificationError } = useNotification()
  
  const handlePrizeUpdate = async (values) => {
    try {
      const formData = new FormData()
      let {id: prizeId, image, ...remValues} = values
      
      if (Array.isArray(image)) 
        formData.append('image', image[0])

      await dispatch(updateClientPrizeData({
        prizeId,
        values: remValues
      })).unwrap()
      await dispatch(updateClientPrizeDataImage({
        prizeId,
        formData
      })).unwrap()
      showNotificationSuccess('Succeed', 'Update Success')
    } catch (prizeUpdateError) {
      console.error(prizeUpdateError)
      showNotificationError('Error', 'Update Fail')
    }
  }

  const handleWinnerPrizeSubmit = async (values) => {
    await handlePrizeUpdate(values)
  }

  const handleSurveyPrizeSubmit = async (values) => {
    await handlePrizeUpdate(values)
  }

  return (
    <div className='client-prize-container'>
      <div className="prize-config__content">

        <div className="prize-config--left">
          <PrizeForm 
            onSubmit={handleWinnerPrizeSubmit} 
            client={client} />
        </div>

        <div className="prize-config--right">
          <PrizeForm 
            onSubmit={handleSurveyPrizeSubmit} 
            client={client} 
            prefix="survey"
          />
        </div>
        
      </div>
    </div>
  );
};

export default ClientConfigPrizeContainer;