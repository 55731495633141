import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import LoginForm from "../components/forms/LoginForm"
import {setUser} from '../utils/token'
import {loginUser} from '../states/auth'
import {useNotification} from '../hooks'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const {showNotificationSuccess} = useNotification()
  const [error, setError] = useState()

  // loader on the login button to indicate busy 
  const [ loading, setLoading] = useState(false)
  
  // where we coming from , so we can redirect back there
  // after successfull login or we just go to the dashboard
  const from = location.state?.from?.pathname || "/" 

  const handleLogin = async (values) => {
    console.log("Logging in .. ")
    setError('')
    const {username, password} = values
    setLoading(true)
    
    // perform login operation -> set local storage -> remove loader -> redirect
    try {
      const response = await dispatch(loginUser({username, password})).unwrap()
      setUser(response)
      navigate(from, {replace: true})      
      showNotificationSuccess("Logged In")
    } catch (loginError) {
      setLoading(false)
      console.error("Login Error: ", loginError)
      if (loginError.message.includes('401')) {
        setError('Invalid Credentials Entered ')
      } else if (loginError.message.includes('Network Error')) {
        setError('Network Error !')
      }
    }
  }
  
  return (
    <div className="login-container">
      <div className="header__logo">
        <img src="/logo.png" width={300}/>
      </div>
      <div className="login-container__card">
        <div className="login-container__header">
          LOGIN
        </div>
        {error && <div className="login-container__error">
          {error}
        </div>}
        <div className="login-container__content">
          <LoginForm handleSubmit={handleLogin} loading={loading} />
        </div>
      </div>
    </div>
  )
}

export default Login