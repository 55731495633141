import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainWrapper from "../containers/wrapper";
import Dashboard from "../containers/dashboard";
import Login from "../containers/login";
import ClientRoutes from "./client";
import DeviceRoutes from "./device";
import StoreRoutes from "./store";
import ReportRoutes from "./report";
import UserRoutes from "./user";
import AiStatRoutes from "./ai-stat";
import MapPage from "../pages/map";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainWrapper />}>
          <Route index element={<Dashboard />} />
          <Route path="clients/*" element={<ClientRoutes />} />
          <Route path="devices/*" element={<DeviceRoutes />} />
          <Route path="stores/*" element={<StoreRoutes />} />
          <Route path="reports/*" element={<ReportRoutes />} />
          <Route path="users/*" element={<UserRoutes />} />
          <Route path="stat/*" element={<AiStatRoutes />} />
          <Route path="map/" element={<MapPage />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="*" element={<h3>404 - Go Away </h3>} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
