import { useDeviceStatOverview } from "../../../hooks/ai-stat";
import StatBox from "../stat-box";

const DeviceChartOverView = ({ storeId }) => {
  const data = useDeviceStatOverview(storeId);
  return (
    <div className="chart-overview">
      <StatBox value={data?.total_today ?? 0} label="total today" />
      <StatBox value={data?.total_week ?? 0} label="total This Week" />
      <StatBox value={data?.ath ?? 0} label="All Time High" />
      <StatBox value={0} label="---" />
    </div>
  );
};

export default DeviceChartOverView;
