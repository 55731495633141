import React from 'react';
import ComponentLoader from '../loader/ComponentLoader';

const ConfigStoreItem = ({store}) => {
  return store ? (
    <div className='config-store-item'>
      <div className="title">{store?.name}</div>
      <div className="ticker">
        <ion-icon name="checkmark-circle-outline"></ion-icon>
      </div>
      <div className="address">{store?.address}</div>
      <div className="status">active</div>
      {/* <div className="delete"> 
        <ion-icon name="trash-outline"></ion-icon>
      </div> */}
    </div>
  ) : <ComponentLoader />;
};

export default ConfigStoreItem;