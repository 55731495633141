import {useSelector} from 'react-redux';

export const useLocations = () => {
  return useSelector(state => state.location.locations)
}

export const useCities = () => {
  return useSelector(state => state.location?.cities)
}

export const useStates = () => {
  return useSelector(state => state.location?.states)
}

export const useCountries = () => {
  return useSelector(state => state.location?.countries)
}