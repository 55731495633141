import { useSelector } from "react-redux"
import {isMachineOffline} from "../utils"

// machine
export const useDevices = () => {
  const devices = useSelector(state => state.device?.devices)
  return devices
}

export const useDevice = (deviceId) => {
  const devices = useDevices()
  return devices && devices.find(m => m.id === deviceId)
}


export const useStoreDevices = (storeId) => {
  const devices = useDevices()
  return devices?.filter(m => m.store === storeId)
}

export const useOnlineDevices = () => {
  const devices = useDevices()
  console.log('devices: ', devices)
  return devices?.filter(m => !isMachineOffline(m.seen)) ?? []
}

export const useOfflineDevices = () => {
  const devices = useDevices()
  return devices?.filter(m => isMachineOffline(m.seen)) ?? []
}

export const usePendingDevices = () => {
  // pending devices are devices with some missing properties 
  // such as remote info , no store assigned and stuffs 
  const devices = useDevices()
  return devices?.filter(m => m.store === null ) ?? []
}

// export const useMachineClients = (machineId) => {
//   const machine = useMachine(machineId)

//   // machine -> store -> videos -> owner
//   const videos = useStoreVideos(machine?.store)

//   const byVideos = videos.reduce((acc, cv) => acc.concat(cv.owner), []) // gather all owners

//   // machine -> store -> photobooth -> owner
//   const booth = useStorePhotoBooth(machine?.store)
//   const byBooth = booth?.owner

//   // machine -> store -> stw -> owner
//   const wheel = useStoreWheel(machine?.store)
//   const byWheel = wheel?.owner

//   console.log('videos: ', videos, ' byVideos: ', byVideos, 'booth: ', booth, 'wheel: ', wheel)

//   // return byVideos.concat(byBooth, byWheel)
//   return { byVideos: videos, videoOwners: byVideos, byBooth: booth, byWheel: wheel }
// }


