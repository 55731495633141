import {createContext, useRef, useState} from 'react'
import ConfirmationBox from "../../components/confirmation"
export const ConfirmationContext = createContext()


const ConfirmationProvider = ({children}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState('Are you sure ?')
  let resolver = useRef();

  const handleConfirm = (resolveAction) => {
    setModalOpen(false)
    resolver?.current?.(true)
  }

  const handleCancel = (resolveAction) => {
    setModalOpen(false)
    resolver?.current?.(false)

  }

  const confirmAction = (messageText) => {
    setModalText(messageText)
    setModalOpen(true)
    return new Promise( resolve => { 
      resolver.current = resolve
    })
  }

  return (
    <ConfirmationContext.Provider value={{confirmAction}}>
      {children}

      { modalOpen ? 
        <ConfirmationBox 
          handleConfirm={handleConfirm} 
          handleCancel={handleCancel} 
          title={modalText}
          /> 
        : null
      }
    </ConfirmationContext.Provider>
  )
}

export default ConfirmationProvider