const StatBox = ({ value, label }) => {
  return (
    <div className="stat-page__stat-box">
      <span className="value">{value}</span>
      <span className="label">{label.toUpperCase()}</span>
    </div>
  );
};

export default StatBox;
