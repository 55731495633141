import React from 'react';
import DashBox from '../../components/dashbox';
import TopReportBox from '../../components/report/TopReportBox';
import { useLeads, useStatQRCount } from '../../hooks/stat'

const ReportScansContainer = () => {
  const qrCount = useStatQRCount()

  // how do we compute our data base on clients
  const computedData = {}

  return (
    <div className='report-scans-container'>
      <div className="header-box">
        <DashBox label="Scans Total" value={parseInt(qrCount)} />
      </div>
      <div className="content">
        <TopReportBox data={[{primary: 'Total', value: 235, max: 500}]} title={"Cococola"}/>
        <TopReportBox data={[{primary: 'Total', value: 235, max: 500}]} title={"Wings Empire"}/>
        <TopReportBox data={[{primary: 'Total', value: 235, max: 500}]} title={"Mc Donalds"}/>
      </div>
    </div>
  );
};

export default ReportScansContainer;