import React from 'react';
import { useStoresByIds } from '../../hooks/store';

const StoreTableList = ({storesIds, onItemClick, onItemDelete, loading}) => {
  const tableStores = useStoresByIds(storesIds)
  return (
    <div className='store-table-list'>
      <div className="store-table-list__content">
        {[1, 2].map( header => (
          <div key={header} className="store-table-list__content-header-item">
            <li>store name</li>
            <li>Address</li>
            <li>delete</li>
          </div>
        ))}
        {tableStores?.map( store => (
          <div 
            key={store.id} 
            className="store-table-list__content-item"
            onClick={e => onItemClick(store)}
          >
            <div className="store-name">{store.name}</div>
            <div className="store-address">{store.address?.slice(0, 20)} ..</div>
            <span className='store-delete' onClick={e => onItemDelete(store)}>
              <ion-icon name="trash"></ion-icon>
            </span>
          </div>
        ))}
        
      </div>
      { (!tableStores || loading)  && (
        <div className="store-table-list__loader"></div>
      )}
    </div>
  );
};

export default StoreTableList;