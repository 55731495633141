import {Route, Routes} from 'react-router-dom'
import DevicePage from '../pages/device'
import { DeviceDashboard, DeviceListContainer, DeviceDetailContainer } from '../containers/device'

const DeviceRoutes = () => (
  <Routes>
    <Route path="" element={<DevicePage />} >
      <Route index element={<DeviceDashboard /> } />
      <Route path="list/" element={<DeviceListContainer /> } />
      <Route path=":deviceId/" element={<DeviceDetailContainer /> } />
    </Route>
  </Routes>
)

export default DeviceRoutes