import classNames from 'classnames';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import DateTimeBox from '../date/DateTimeBox'

const StoreCard = ({store}) => {
  const navigate = useNavigate()
  const {name, id: storeId, location, created} = store
  const isNewStore = () => {
    if ( created ) 
      return (new Date()) - (new Date(created)).getTime() <  ( 600 * 1000)
    return false
  }

  return (
    <div 
      className={classNames('store-card', {'store-card--new': isNewStore()})} 
      onClick={e => navigate(`/stores/${storeId}`)}
    >
      <div className="store-card__header">
        <div className="store-card__header-title">{name}</div>
        <DateTimeBox />
      </div>
      <div className="store-card__address">
        <span className='icon'><ion-icon name="location-outline"></ion-icon></span>
        <span className='text'>
          { location ? `${location.address}, ${location.city}, ${location.state}, ${location.country}` : 'N/A'}
        </span>
      </div>
    </div>
  );
};

export default StoreCard;