import {useQuery} from 'react-query'
import { Navigate } from 'react-router-dom';
import { makeRequest } from '../utils/axios'; 

import DashBox from '../components/dashbox'
import { useOnlineDevices, useDevices, useOfflineDevices } from '../hooks/device'
import {useStatQRCount} from '../hooks/stat';
import { useClients } from '../hooks/client';
import { useStores } from '../hooks/store';
import {useUser} from '../hooks/auth';

const Dashboard = () => {
  const user = useUser()
  const qrCount = useStatQRCount()
  const devices = useDevices()
  const activeDevices = useOnlineDevices()
  const offlineDevices = useOfflineDevices()
  const clients = useClients()
  const stores = useStores()

  const  {data} = useQuery('dashboard', () => 
    makeRequest(`report/leads/`));

  if (!user) return <p>Loading ...</p>;
  
  // could it be a good idea to show the report container here 
  // or just redirect to the client specific report container 
  if (user.is_clientuser) {
   // get the binded client for the user 
    return <Navigate to={`/clients/${user.client}/reports/`}/>
  }

  return (
    <div className="dashboard-container">
      <DashBox label="Scans Total" value={Number(qrCount)} />
      <DashBox label="Leads Total" value={data?.leads_total} />
      <DashBox label="Winner Total" value={data?.winner_total} />
      <DashBox label="Vabe Devices" value={devices?.length} />
      <DashBox label="Online Devices" value={activeDevices?.length} />
      <DashBox label="Offline Devices" value={offlineDevices?.length} />
      <DashBox label="Clients" value={clients?.length} />
      <DashBox label="Stores" value={stores?.length} />
    </div>
  ) 
}

export default Dashboard;
