import React from 'react';
import {useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import { makeRequest } from '../../utils/axios'; 
import DashBox from '../../components/dashbox';
import { useLeads, useStatQRCount } from '../../hooks/stat'
import { useGalleryImages } from '../../hooks/gallery';

const ReportDashboard = () => {
  const navigate = useNavigate()
  const qrCount = useStatQRCount()

  const  {data: leads} = useQuery('g-leads', () => makeRequest(`report/leads/`));

  return (
    <div className='report-dashboard'>
      <DashBox 
        label="Scans Total" 
        value={parseInt(qrCount)} 
        onClick={e => navigate('scans/')}
      />      
      
      <DashBox 
        label="Leads Total" 
        value={leads?.leads_total} 
        onClick={e => navigate('leads/')}
      />      
      
      <DashBox 
        label="Winners Total" 
        value={leads?.winner_total} 
        onClick={e => navigate('winners/')}
      />

      <DashBox 
        label="Surveys Total" 
        value={leads?.survey_total} 
        onClick={e => navigate('surveys/')}
      />      

      <DashBox 
        label="Photos" 
        value={leads?.photos_total} 
        onClick={e => navigate('photos/')}
      />      
    </div>
  );
};

export default ReportDashboard