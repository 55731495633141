import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { useDays, useActions} from '../../hooks/schedule'
import { useNotification} from '../../hooks';
import { createSchedule} from '../../states/schedule';
import classNames from 'classnames';

const ClientScheduleCreateForm = ({device, activeSchedule}) => {
  const dispatch = useDispatch()
  const actions = useActions()
  const scheduleDays = useDays()
  const {showNotificationSuccess, showNotificationError} = useNotification()

  const [days, setDays] = useState([])
  const [time, setTime] = useState('')
  const [daysActive, setDaysActive]= useState(false)

  const handleScheduleCreate = async () => {
    const action = adaptAction()
    try {
      await dispatch(createSchedule({
        name: `${action.name} ( ${device.name} )`,
        time,
        machine: device.id,
        intervals: 1,
        action: action.id,
        days
      })).unwrap()
      showNotificationSuccess('Success', `Schedule has been succesfully created`)
    } catch (createError) {
      console.error(createError)
      showNotificationError('Update', 'Failed to Create Schedule')
    }
  }

  const adaptAction = () => {
    return actions.find(action => action.name.toLowerCase().includes(activeSchedule))
  }

  const handleDayChange = (day) => {
    if (days.includes(day))
      setDays(days => days.filter(d => d !== day))
    else
      setDays([...days, day])
  }

  

  return (
    <div className="schedule-create-form">
      <div className="form__group">
        <input type="time" value={time} onChange={e => setTime(e.target.value)}/>
      </div>

      <div className="form__group">
        <div className="days-btn" onClick={e => setDaysActive(p => !p)}>frequency</div>
        <div className={classNames("schedule-create-form__days-items", {"active": daysActive})}>
          {scheduleDays && scheduleDays.map((d, i) => (
            <div className="schedule-create-form__days-item" key={i}>
              <label htmlFor={`day_create_${i}`}>
              <input
                key={i} 
                type="checkbox" 
                id={`day_create_${i}`} 
                checked={days.includes(d.id)} 
                onChange={() => handleDayChange(d.id)} 
              />
              {d.name}</label>
            </div>
          ))}
        </div>
      </div>
      <button className="" onClick={handleScheduleCreate}>
        <ion-icon name="add-outline"></ion-icon>
      </button>
  </div>
  )
}

export default ClientScheduleCreateForm