import {Route, Routes} from 'react-router-dom'
import ReportPage from '../pages/report';
import {
  ReportDashboard ,
  ReportContainer,
  ReportScansContainer,
  ReportLeadsContainer,
  ReportWinnersContainer,
  ReportPhotosContainer,
} from '../containers/report';

const ReportRoutes = () => (
  <Routes>
    <Route path="/" element={<ReportPage />} >
      <Route index element={<ReportDashboard /> } />
      <Route path="/*" element={<ReportContainer />}>
        <Route path="scans/" element={<ReportScansContainer />} />
        <Route path="surveys/" element={<ReportLeadsContainer />} />
        <Route path="winners/" element={<ReportWinnersContainer />} />
        <Route path="photos/" element={<ReportPhotosContainer />} />
      </Route>
    </Route>
  </Routes>
)

export default ReportRoutes