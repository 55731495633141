import classNames from "classnames";
import { useEffect, useState } from "react";
import { useStoreStatMore } from "../../../hooks/ai-stat";

const StatBoxNormal = ({
  value,
  label,
  bigger = false,
  highlighted = false,
  spanned = false,
  percentage = true,
}) => {
  return (
    <div
      className={classNames("stat-box-v2", {
        "stat-box-v2--bigger": bigger,
        "stat-box-v2--spanned": spanned,
        "stat-box-v2--highlighted": highlighted,
      })}
    >
      <div className="stat-box-v2__value">
        {value}
        {percentage ? <sup>%</sup> : ""}
      </div>
      <div className="stat-box-v2__label">{label}</div>
    </div>
  );
};

const StoreMoreDetailV2 = ({ storeId }) => {
  //   const todayString = new Date().toISOString().split("T")[0];
  //   const [currentDate, setCurrentDate] = useState(todayString);
  const [dateString, setDateString] = useState("");
  const [dateRangeI, setDateRangeI] = useState("");
  const [dateRangeII, setDateRangeII] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [timeframe, setTimeframe] = useState("today");
  const data = useStoreStatMore(storeId, dateString, dateRange, timeframe);

  console.log("StoreMoreDetailV2 data: ", data);

  //   useEffect(() => setTimeframe("month"), []);

  const FrameButton = ({ children, frame }) => {
    return (
      <button
        className={classNames("detail-v2__btn", {
          "detail-v2__btn--selected": timeframe === frame,
        })}
        onClick={(e) => handleTimeframe(frame)}
      >
        {children}
      </button>
    );
  };

  const _handleDownload = () => { };

  const DetailHeader = () => {
    return (
      <div className="detail-v2__header">
        <div className="detail-v2__header--left">
          <div className="detail-v2__timeframe">
            <FrameButton frame="today">today</FrameButton>
            <FrameButton frame="week">7 days</FrameButton>
            <FrameButton frame="month">30 days</FrameButton>
            <FrameButton frame="all">lifetime</FrameButton>
          </div>
          <div className="detail-v2__date-range">
            <div className="detail-v2__date">
              <span>From:</span>
              <input
                type="date"
                value={dateRangeI}
                onChange={(e) => setDateRangeI(e.target.value)}
              />
            </div>
            <div className="detail-v2__date">
              <span>To:</span>
              <input type="date" value={dateRangeII} onChange={_setDateRange} />
            </div>
          </div>
        </div>
        <a
          className="detail-v2__download"
          // href={`http://127.0.0.1:8000/api/ai/download/${storeId}/`}
          href={`https://api.vabe.live/api/ai/download/${storeId}/`}
        >
          download
        </a>
      </div>
    );
  };

  const _setDateRange = (event) => {
    if (dateRangeI.length < 1) {
      alert("Need to set the from value");
      return;
    }
    const dateValue = `${dateRangeI};${event.target.value}`;
    setTimeframe("");
    setDateRangeII(event.target.value);
    setDateRange(dateValue);
    console.log("date range -- ", dateValue);
  };

  const handleTimeframe = (frameString) => {
    setTimeframe(frameString);
  };

  const _getEngagementTotal = () => {
    const _arr = _getEngagements();
    return _arr.reduce((acc, curr) => acc + curr, 0);
  };

  const _getEngagements = () => {
    return Object.values(data?.engagement_data ?? {});
  };

  const _getGenders = () => {
    return Object.values(data?.gender_data ?? {});
  };

  const _percentage = (value, value_list) => {
    const _total = value_list.reduce((acc, c) => acc + c, 0);
    const p = Number.parseInt((value / _total) * 100);
    return isNaN(p) ? 0 : p;
  };

  const _getLanguages = () => {
    return Object.values(data?.language_data ?? {});
  };

  const _getAges = () => {
    return Object.values(data?.age_data ?? {});
  };

  const _getEthnicities = () => {
    return data?.ethnicity_data ?? {};
  };

  // const _getEthnicitiesTotal = () => {
  //   const values = Objects.values(_getEthnicities);
  //   return values.reduce((acc, c) => acc + c, 0);
  // }

  const _percentageV2 = (value, valuesObjects) => {
    const values = Object.values(valuesObjects);
    const _total = values.reduce((acc, c) => acc + c, 0);
    const p = Number.parseInt((value / _total) * 100);
    return isNaN(p) ? 0 : p;
  };

  const _getMax = (values) => {
    if (new Set(values).size === 1) return 12392839238;
    return Math.max(...values);
  };

  const _engagementsLabels = ["open - noon", "12:01pm - 4:30", "4:31-close"];
  const _genderLabels = ["Male subjects", "Female subjects"];
  const _languageLabels = ["Lang | english", "lang | spanish", "lang | other"];
  const _ageLabels = [
    "age senior | 55+",
    "age adults | 35+",
    "young adults | 20+",
    "minors | teens 15+",
    "minors | kids > 14",
  ];
  const _ethnicityLabels = [
    "White Caucasian",
    "African American",
    "Hispanic Latino",
    "Asian",
    "Other",
  ];

  console.log("eth data: ", _getEthnicities());
  return (
    <div className="detail-v2">
      <h2>AI campaign report</h2>
      <DetailHeader />
      <div className="detail-v2__content">
        <div className="detail-v2__stat">
          <StatBoxNormal
            label="engagement total"
            value={_getEngagementTotal()}
            bigger
            spanned
            highlighted
            percentage={false}
          />

          {_getEngagements().map((value, index) => (
            <StatBoxNormal
              key={_engagementsLabels[index]}
              label={_engagementsLabels[index]}
              value={value}
              bigger
              highlighted={_getMax(_getEngagements()) === value}
              percentage={false}
            />
          ))}

          <div className="detail-v2__stat--line"></div>
          {_getGenders().map((value, index) => (
            <StatBoxNormal
              key={_genderLabels[index]}
              label={_genderLabels[index]}
              value={_percentage(value, _getGenders())}
              highlighted={_getMax(_getGenders()) === value}
            />
          ))}

          {_getLanguages().map((value, index) => (
            <StatBoxNormal
              key={_languageLabels[index]}
              label={_languageLabels[index]}
              value={_percentage(value, _getLanguages())}
              highlighted={_getMax(_getLanguages()) === value}
            />
          ))}
          <div className="detail-v2__stat--line"></div>

          {_getAges().map((value, index) => (
            <StatBoxNormal
              key={_ageLabels[index]}
              label={_ageLabels[index]}
              value={_percentage(value, _getAges())}
              highlighted={_getMax(_getAges()) === value}
            />
          ))}
          <div className="detail-v2__stat--line"></div>

          {_ethnicityLabels.map((label) => (
            <StatBoxNormal
              key={label}
              label={label}
              value={_percentageV2(_getEthnicities()[label], _getEthnicities())}
              highlighted={
                _getMax(Object.values(_getEthnicities())) ===
                _getEthnicities()[label]
              }
            />
          ))}
        </div>
      </div>
      <div className="detail-v2__footer">
        Disclaimer: The data provided is for informational purposes only and is
        based on estimates derived from activity. While we make every effort to
        ensure the accuracy of the data we cannot guarantee its completeness or
        reliability. The information provided should not be relied upon for
        making business or financial decisions without independent verification.
        We are not liable for any damages or losses that may arise from the use
        of this information.
      </div>
    </div>
  );
};

export default StoreMoreDetailV2;
