import React from 'react';
import classNames from 'classnames';

const OutlineButton = ({children, loading, icon, onClick, style, disabled, active}) => {
  return (
    <button
    className={classNames({
      'outline-button': true, 
      'outline-button--loading': loading,
      'outline-button--iconic': !!icon,
      'outline-button--active': active
    },)}
    onClick={onClick}
    style={style}
    disabled={disabled}
    >
      {loading && <div className="loader"></div> }
      {children}
      {icon && <div className="icon"><ion-icon name={icon}></ion-icon></div> }
    </button>
  );
};

export default OutlineButton;