import Button from "../button"

const ConfirmationBox = ({title, handleConfirm, handleCancel}) => {
  return (
    <div className="confirmation-box">
      <div className="confirmation-box__content">
        <div className="confirmation-box__title">
          {title}
        </div>
        <div className="confirmation-box__actions">
          <Button onClick={handleConfirm}>Confirm</Button>
          <Button onClick={handleCancel} type="danger">Cancel</Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationBox