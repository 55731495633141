import { useContext } from "react"
import {ConfirmationContext} from "../contexts/confirmation"
import { NotificationContext } from "../contexts/notification"

export const useNotification = () => {
  const notificationContext = useContext(NotificationContext)
  // we got to check if the context is available so we don't 
  // expect what is not coming 
  if ( notificationContext === undefined)
    throw new Error('Notification Context Undefined')
  return notificationContext
}

export const useConfirmation = () => {
  const confirmationContext = useContext(ConfirmationContext)
  if ( confirmationContext === undefined) {
    throw new Error('Confirmation Context Undefined')
  }
  return confirmationContext
}
