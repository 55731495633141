import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useStoreStatOverview, useStoreStatMore } from "../../hooks/ai-stat";

import { useStoreDevices } from "../../hooks/device";
import { useStore } from "../../hooks/store";
import StatDeviceItem from "./components/device-item";
import StoreChartOverview from "./components/store-chart-overview";
import { StoreChartContainer } from "./components/store-charts";
import StoreMoreDetailV2 from "./components/detail-v2";

import StatBox from "./stat-box";

const StoreMoreDetail = ({ storeId }) => {
  const todayString = new Date().toISOString().split("T")[0];
  const [currentDate, setCurrentDate] = useState(todayString);
  const data = useStoreStatMore(storeId, currentDate);

  console.log("current date: ", currentDate);
  return (
    <div className="store-more-detail">
      <div className="day-selector">
        <label htmlFor="select-detail-date">Select Date</label>
        <input
          type="date"
          id="select-detail-date"
          value={currentDate}
          onChange={(e) => setCurrentDate(e.target.value)}
        />
      </div>
      <MoreDetailView data={data} key={currentDate} />
    </div>
  );
};

export const MoreDetailView = ({ data }) => {
  return (
    <div className="stat-overview">
      <div className="stat-overview__inner">
        <div className="title">Engagement data</div>
        <div className="content">
          {Object.keys(data?.engagement_today ?? {}).length > 0 ? (
            Object.entries(data.engagement_today).map(([bracket, count]) => (
              <StatBox value={count ?? 0} label={`${bracket}`} />
            ))
          ) : (
            <p>There're no Age data for this day</p>
          )}
        </div>{" "}
        {/* 9 - 12:59 1 = 4:59 5-9 */}
      </div>

      <div className="stat-overview__inner">
        <div className="title">Gender data</div>
        <div className="content">
          {Object.keys(data?.gender_today ?? {}).length > 0 ? (
            Object.entries({ female: 0, ...data.gender_today }).map(
              ([bracket, count]) => (
                <StatBox value={count ?? 0} label={`Age ${bracket}`} />
              )
            )
          ) : (
            <p>There're no Age data for this day</p>
          )}
        </div>
      </div>

      <div className="stat-overview__inner">
        <div className="title">Age data</div>
        <div className="content">
          {Object.keys(data?.age_today ?? {}).length > 0 ? (
            Object.entries(data.age_today).map(([gender, count]) => (
              <StatBox value={count ?? 0} label={`${gender}`} />
            ))
          ) : (
            <p>There're no gender data for this day</p>
          )}
        </div>
      </div>

      <div className="stat-overview__inner">
        <div className="title">Ethnicity data</div>
        <div className="content">Not Available</div>
      </div>

      <div className="stat-overview__inner">
        <div className="title">Spoken Language data</div>
        <div className="content">Not Available</div>
      </div>
    </div>
  );
};

const StoreStatDetail = () => {
  // how are we sure the store Id is a valid one ??
  const { storeId } = useParams();
  const store = useStore(storeId);
  const storeDevices = useStoreDevices(storeId);

  return (
    <div className="stat-page__content">
      <div className="stat-page__content-header">
        <StoreChartOverview storeId={storeId} />
      </div>

      <StoreMoreDetailV2 storeId={storeId} />

      <div className="stat-page__content-main">
        {/* <div className="stat-page__charts">
          <StoreChartContainer storeId={storeId} />
        </div> */}

        <div className="stat-page__devices">
          <h4 className="title">Store Devices - {store.name}</h4>
          <div className="stat-page__devices-content">
            {storeDevices.map((device) => (
              <StatDeviceItem device={device} key={device.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreStatDetail;
