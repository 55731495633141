import { getLocalRefreshToken, updateLocalAccessToken } from "./token"
import { axiosInstance } from './axios'

export const loginUserLocal = async () => {
  console.log('Trying local login .. ')
  
  const refreshToken = getLocalRefreshToken()

  // return false if local refresh token does not exists
  if (!refreshToken) return false

  // request new access token from server using local refresh token
  // if request okay , set new local access token, set redux state logged In
  // if failed, err and return false
  try {
    const result = await axiosInstance.post("auth/token/refresh/", { refresh: refreshToken })
    updateLocalAccessToken(result.data.access)
    console.log('Passed local login .. ')
  } catch (error) {
    console.error('Failed to login from local data')
    return false
  }
  return true
}