import {useMemo} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import UserAddHeader from './UserAddHeader';
import {Form, Field} from 'react-final-form';
import OutlineButton from '../../components/button/OutlineButton';
import InputField from './components/InputField';
import CustomSelect from './components/CustomSelect'
import {useClients} from '../../hooks/client';
import {useNotification, useConfirmation} from '../../hooks';
import { useClientUser } from '../../hooks/auth';
import {updateClientUser, deleteClientUser} from '../../states/auth';

const UserClientDetailContainer = () => {
  const dispatch = useDispatch();
  const clients = useClients()
  const navigate = useNavigate()
  const { showNotificationError, showNotificationSuccess} = useNotification()
  const {confirmAction} = useConfirmation()
  const params = useParams()
  const clientUser = useClientUser(params.userId)
  
  const options = []

  const handleClientUserUpdate = async (values) => {
    const user  = values?.user
    const client = values?.client?.value ?? null
    try {
      await dispatch(updateClientUser({
        userId: params.userId,
        user, 
        client
      })).unwrap()
      showNotificationSuccess('Update Success', '')
    } catch (userUpdateError) {
      showNotificationError('Update Error')
    }
  }

  const handleDelete = async () => {
    const proceed = await confirmAction('Are you sure you want to delete Admin user ?')
    if(proceed){
      try {
        await dispatch(deleteClientUser({userId: params.userId})).unwrap()
        showNotificationSuccess('User Deleted')
        navigate('/users/list/')
      } catch (error) {
        showNotificationError('Failed to delete')
        console.error(error)
      }
    }
  }

  const clientOptions = useMemo( () => (
    clients.map(client => ({value: client.id, label: client.name}) ) 
  ), [clients] )

  const selectedClient = clientOptions?.find(c => c.value === clientUser?.client)

  return (
    <div className="user-add-container">
      
      <div className="user-add-container__header">
        <UserAddHeader  title="Client" secondary="User Detail"/>
      </div>

      <div className="user-add-container__content">
        <Form 
          onSubmit={handleClientUserUpdate} 
          initialValues={{...clientUser, client: selectedClient}}
        >
          {({handleSubmit, values, submitting}) => (
            <>
            <div className="contact-section">
              <InputField label="username" name="user.username" />
              <InputField label="password" name="user.password" />
              <InputField label="First Name" name="user.first_name"/>
              <InputField  label="Last Name" name="user.last_name"/>
              <InputField  label="Email Address" name="user.email"/>
              <InputField  
                label="Cell Number" 
                placeholder="Number with availability required" 
                name="user.phone" />
            </div>
          
            <div className="criteria-section">
              <div className="criteria-section__header">
                <span>CRITERIAS</span>
              </div>

              <div className="criteria-section__content">
                <Field name="client">
                  { ({input, ...rest}) => (
                  <CustomSelect 
                    options={clientOptions} 
                    label="Client Management"
                    {...rest} 
                    {...input}
                  />
                  )}
                </Field>
                <div></div>
                <CustomSelect
                  options={options} 
                  placeholder="select one or more state"
                  label="Select by state"
                />
                <CustomSelect 
                  options={options} 
                  label="Select by city"
                  placeholder="select one or more city"
                />
              </div>
            </div>

            <div className="actions-section">
              <OutlineButton icon="save-outline" onClick={handleSubmit}>Update Client</OutlineButton>
              <OutlineButton icon="trash-outline" onClick={handleDelete} loading={submitting}>
                Delete
              </OutlineButton>
            </div>
            </>
            )}
          </Form>
      </div>
    </div>
  );
};

export default UserClientDetailContainer;