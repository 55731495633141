import Select from 'react-select';

const CustomSelect = ({options, placeholder, label, isMulti, ...rest}) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <Select
        placeholder={placeholder}
        options={options} 
        isClearable
        isMulti={isMulti ?? false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral0: '#00000090',
            primary25: '#8f9aa2',
            neutral80: '#00F',
          }
        })}
        {...rest}
      />
    </div>
  )
}

export default CustomSelect