import {configureStore} from '@reduxjs/toolkit';
import authReducer from './auth';
import statReducer from './stat';
import deviceReducer from './device'
import clientReducer from './client'
import storeReducer from './store'
import videoReducer from './video';
import boothReducer from './photobooth';
import stwReducer from './stw';
import scheduleReducer from './schedule';
import galleryReducer from './gallery';
import locationReducer from './location'

const store = configureStore({
  reducer: {
    auth: authReducer,
    stat: statReducer,
    device: deviceReducer, 
    client: clientReducer,
    store: storeReducer,
    schedule: scheduleReducer,
    video: videoReducer,
    booth: boothReducer, 
    stw: stwReducer,
    gallery: galleryReducer,
    location: locationReducer
  }
})

export default store