import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import DateTimeBox from '../../components/date/DateTimeBox';

const ReportLink = ({path, children, to, ...rest}) => (
  <NavLink {...rest} to={to} active={to.includes(path)} >
    {children}
  </NavLink>
)


const ClientReportContainer = () => {
  const {pathname} = useLocation()
  const reportType = pathname.slice(54, -1)
  return (
    <div className='client-reports-container'>
      <div className="header">
        <div className="header__left">
          <span className='primary-text'>{reportType.toUpperCase()}</span>
          <span className='secondary-text'>client stats</span>
        </div>
        <div className="header__right">
          <DateTimeBox />
        </div>
      </div>
      <div className="header-nav">
        <ReportLink to="survey/" className="header-nav__item">survey</ReportLink>
        <ReportLink to="leads/" className="header-nav__item">leads</ReportLink>
        <ReportLink to="winners/" className="header-nav__item">winners</ReportLink>
        {/* <ReportLink to="scans/" className="header-nav__item">scans</ReportLink> */}
        <ReportLink to="gallery/" className="header-nav__item">gallery</ReportLink>
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default ClientReportContainer;