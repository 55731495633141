import { createContext } from "react";
import Notification from "rc-notification";
import NotificationBox from "../../components/notification";

export const NotificationContext = createContext('notification')


const NotificationProvider = ({children}) => {
  let notification;
  const NOTIFICATION_ERROR = 'error'
  const NOTIFICATION_SUCCESS = 'success'

  Notification.newInstance({ style: {position: 'fixed', left: '-10px', top: '10rem', zIndex: '9999'}}, n => notification = n)

  const _showNotification = (title, message, type) => {
    notification.notice({
      content: <NotificationBox title={title} message={message} type={type} />,
      duration: 3
    })
  }

  const showNotificationError = (title, message) => {
    _showNotification(title, message, NOTIFICATION_ERROR)
  }

  const showNotificationSuccess = (title, message) => {
    _showNotification(title, message, NOTIFICATION_SUCCESS)
  }

  return (
    <NotificationContext.Provider 
      value={{
        notification, 
        showNotificationError,
        showNotificationSuccess
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider