import usmap from '../assets/usamap.json';
import usstates from '../assets/usstates.json';
import MapView from '../components/maps/MapView';
import { useState } from 'react';
import { useMap } from '../hooks/map';
import { useStores } from '../hooks/store';
import { useStoreDevices } from '../hooks/device';

import GoogleMapReact from 'google-map-react';


// usmap viewbox 100 100 1200 400

function resolveUSState(stateISO) {
  if (!stateISO) return 'N/A';
  return usstates[stateISO.toUpperCase()]
}

function USMap({ onClick }) {
  return (
    <MapView
      mapData={usmap}
      onClick={onClick}
      viewBox='100 100 1200 400'
    />
  )
}

function GoogleMapStores() {
  const stores = useStores();
  const location = {
    address: '1600 Amphitheatre Parkway, Mountain View, california.',
    lat: 37.42216,
    lng: -122.08427,
  }
  const MapMarker = ({ store }) => (
    <div onClick={() => console.log('just clicked on map marker! ')}>
      <ion-icon name="storefront"
        style={{ fontSize: '2rem', color: 'yellow' }}>
      </ion-icon>
      <span
        className=""
        style={{ fontWeight: 'bold', color: 'yellow' }}
      >
        {store.name}
      </span>
    </div>
  );

  return (
    <div className="google-map-stores" style={{ width: '100%', height: '500px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCe6MYb3zKy6GYuWZPLDidgC14BiP4NEAQ' }}
        defaultZoom={9}
        defaultCenter={location}
      >
        {stores.map(store => (
          <MapMarker
            store={store}
            lat={Number(store.location.lat)}
            lng={Number(store.location.lng)}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

function MapPage() {
  const [selectedUsState, setSelectedUsState] = useState();
  const [selectedStore, setSelectedStore] = useState();
  const { highlightCountry, clearHighlightedCountries } = useMap();
  const stores = useStores();
  const devices = useStoreDevices(selectedStore?.id ?? '')

  function _usMapClicked(mapItem) {
    console.log('state clicked ', resolveUSState(mapItem));
    setSelectedUsState(mapItem);
    clearHighlightedCountries();
    highlightCountry(mapItem)
  }

  console.log('stores:', stores);
  console.log('devices: ', devices);
  return (
    <div className="map-page-container">
      <div style={{ margin: 'auto', width: '100%', height: '60vh' }}>
        {/* <USMap onClick={_usMapClicked} selectedState={selectedUsState} /> */}
        <GoogleMapStores />
      </div>
      <div className="map-details-container">
        <div className="map-detail-header"></div>
        <div className="map-details-body">
          <p>Selected the state of <span>{resolveUSState(selectedUsState)}</span></p>

          <hr style={{ margin: '1rem 0' }} />
          <div className="map-store-items">
            {stores ?
              stores.filter(store => store.location.state.toLowerCase() === resolveUSState(selectedUsState).toLowerCase())
                .map(store => (
                  <div
                    className={`map-store-item ${selectedStore?.id === store.id ? 'map-store-item--selected' : ''}`}
                    onClick={() => setSelectedStore(store)}
                  >
                    <p className="map-store-item__title">{store.name}</p>
                    <p className="map-store-item__phone">{store.phone}</p>
                  </div>
                )) : <p>No store !</p>}
          </div>

          <hr style={{ margin: '1rem 0' }} />
          <p>Selected store - <span>{selectedStore?.name ?? 'N/A'}</span></p>
          <hr style={{ margin: '1rem 0' }} />

          <div className="map-detail-2">
            {selectedStore ? (
              <div className="map-store-detail">
                <p className="store-name">{selectedStore.name}</p>
                <p>
                  <ion-icon name="call-outline"></ion-icon>
                  <span>{selectedStore.phone}</span>
                </p>
                <p>
                  <ion-icon name="location-outline"></ion-icon>
                  <span>
                    {selectedStore.location.address}, {selectedStore.location.city}, {selectedStore.location.state} {selectedStore.location.country}
                  </span>
                </p>
                <p>
                  <ion-icon name="person-outline"></ion-icon>
                  <span>{selectedStore.rep_name}</span>
                </p>
                <p>
                  <ion-icon name="person-outline"></ion-icon>
                  <span>{selectedStore.email}</span>
                </p>
              </div>
            ) : null}
            <div className="map-store-item-devices">
              {devices ? devices.map(device => (
                <div className="map-device-item">
                  <p className="map-device-item__title">{device.name}</p>
                  <p><ion-icon name="desktop-outline"></ion-icon></p>
                </div>
              )) : <p>No store !</p>}
            </div>
          </div>
        </div>
      </div>
    </div >
  )

}

export default MapPage;
