import moment from 'moment'

const DateTimeBox = ({dateStr}) => {
  // dateStr must be a ISO8601
  const currentDate = Date()
  return (
    <div className="timestamp-box">
      <span className="timestamp-box__label">Time and Date</span>
      <span className="timestamp-box__value">
        {dateStr ? 
          moment(dateStr).format('MM Do YY, h:mm:ss a') :
          moment().format('MM Do YY, h:mm:ss a')
        }
        </span>
    </div>
  )
}

export default DateTimeBox