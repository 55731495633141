import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "../../utils/axios"

export const fetchLocations = createAsyncThunk('location/fetchLocations', 
  async (data) => {
    const response = await axiosInstance.get('location/locations/')
    return response.data
  }
)

export const fetchCountries = createAsyncThunk('location/fetchCountries', 
  async (data) => {
    const response = await axiosInstance.get('location/countries/')
    return response.data
  }
)

export const fetchStates = createAsyncThunk('location/fetchStates', 
  async (data) => {
    const response = await axiosInstance.get('location/states/')
    return response.data
  }
)

export const fetchCities = createAsyncThunk('location/fetchCities', 
  async (data) => {
    const response = await axiosInstance.get('location/cities/')
    return response.data
  }
)

const locationSlice = createSlice({
  name: 'location',
  initialState: {
    locations: null,
    states: null,
    cities: null,
    countries: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLocations.fulfilled, (state, action) => {
      state.locations = action.payload
    })
    .addCase(fetchStates.fulfilled, (state, action) => {
      state.states = action.payload
    })
    .addCase(fetchCities.fulfilled, (state, action) => {
      state.cities = action.payload
    })
  }
})

export default locationSlice.reducer
