import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import SelectAdapter from "../../components/select/SelectAdapter";
import UserAddHeader from "./UserAddHeader";
import InputField from "./components/InputField";
import OutlineButton from "../../components/button/OutlineButton";
import CheckBox from "../../components/fields/CheckBox";
import { useAdminUser, useAdminPermissions } from "../../hooks/auth";
import { useClients } from "../../hooks/client";
import { useStores } from "../../hooks/store";
import { useNotification, useConfirmation } from "../../hooks";
import {
  deleteAdminUser,
  fetchAdminPermission,
  updateAdminUser,
} from "../../states/auth";
import { fetchStates, fetchCities } from "../../states/location";
import { useStates, useCities } from "../../hooks/location";

const UserAdminDetailContainer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const adminUser = useAdminUser(params.userId);
  const clients = useClients();
  const stores = useStores();
  const states = useStates();
  const cities = useCities();
  const permissions = useAdminPermissions();
  const { showNotificationError, showNotificationSuccess } = useNotification();
  const { confirmAction } = useConfirmation();
  const [adminPermissions, setAdminPermissions] = useState([]);

  useEffect(() => {
    async function _f() {
      if (states === null) dispatch(fetchStates());
      if (cities === null) dispatch(fetchCities());
    }
    _f();
  }, []);

  useEffect(() => {
    async function fetchPermission() {
      const response = await dispatch(
        fetchAdminPermission(params.userId)
      ).unwrap();
      setAdminPermissions(response);
    }
    fetchPermission();
  }, []);

  const clientOptions = useMemo(
    () => clients.map((client) => ({ value: client.id, label: client.name })),
    [clients]
  );
  const storeOptions = useMemo(
    () => stores.map((store) => ({ value: store.id, label: store.name })),
    [stores]
  );
  const stateOptions = useMemo(
    () => states?.map((state) => ({ value: state.id, label: state.name })),
    [states]
  );
  const cityOptions = useMemo(
    () => cities?.map((city) => ({ value: city.id, label: city.name })),
    [cities]
  );
  const adminPermissionsCodes = useMemo(
    () => adminPermissions?.map((_p) => _p.codename),
    [adminPermissions]
  );

  const initialValues = useMemo(
    () => ({
      ...adminUser,
      clients: clientOptions.filter((c) =>
        adminUser?.resources_filter?.clients.includes(c.value)
      ),
      stores: storeOptions.filter((s) =>
        adminUser?.resources_filter?.stores.includes(s.value)
      ),
      cities: cityOptions?.filter((s) =>
        adminUser?.resources_filter?.cities.includes(s.value)
      ),
      states: stateOptions?.filter((s) =>
        adminUser?.resources_filter?.states.includes(s.value)
      ),
    }),
    [adminUser]
  );

  const handleAdminUpdate = async (values) => {
    console.log("updating admin data ", values);
    const clients = values?.clients?.map((client) => client.value) ?? [];
    const stores = values?.stores?.map((store) => store.value) ?? [];
    const states = values?.states?.map((state) => state.value) ?? [];
    const cities = values?.cities?.map((city) => city.value) ?? [];
    const user = values?.user;

    try {
      await dispatch(
        updateAdminUser({
          userId: params.userId,
          user,
          clients,
          stores,
          states,
          cities,
          permissions: adminPermissionsCodes,
        })
      ).unwrap();
      showNotificationSuccess("Update Success", "");
    } catch (userUpdateError) {
      showNotificationError("Update Error");
    }
  };

  const handlePermissionChange = (e, permission) => {
    if (e.target.checked) setAdminPermissions((p) => p.concat(permission));
    else
      setAdminPermissions((p) =>
        p.filter((_p) => _p.codename !== permission.codename)
      );
  };

  const handleDelete = async (e) => {
    const proceed = await confirmAction(
      "Are you sure you want to delete Admin user ?"
    );
    if (proceed) {
      try {
        await dispatch(deleteAdminUser({ userId: params.userId })).unwrap();
        showNotificationSuccess("User Deleted");
        navigate("/users/list/");
      } catch (error) {
        showNotificationError("Failed to delete");
        console.error(error);
      }
    }
  };

  return (
    <div className='user-add-container'>
      <div className='user-add-container__header'>
        <UserAddHeader title='Admin' secondary='admin detail' />
      </div>
      <div className='user-add-container__content'>
        <Form onSubmit={handleAdminUpdate} initialValues={initialValues}>
          {({ handleSubmit, values, submitting }) => (
            <>
              <div className='contact-section'>
                <InputField
                  label='username'
                  placeholder='This will be used to login'
                  name='user.username'
                />
                <InputField label='password' name='user.password' />
                <InputField label='First Name' name='user.first_name' />
                <InputField label='Last Name' name='user.last_name' />
                <InputField label='Email Address' name='user.email' />
                <InputField
                  label='Cell Number'
                  placeholder='Number with availability required'
                  name='user.phone'
                />
              </div>

              <div className='criteria-section'>
                <div className='criteria-section__header'>
                  <span>CRITERIAS</span>
                  <span>(select 1 or more options)</span>
                </div>
                <div className='criteria-section__content'>
                  <Field name='clients'>
                    {({ input, ...rest }) => (
                      <SelectAdapter
                        options={clientOptions}
                        label='Client Management'
                        isMulti
                        {...rest}
                        {...input}
                      />
                    )}
                  </Field>

                  <Field name='stores'>
                    {({ input, ...rest }) => (
                      <SelectAdapter
                        options={storeOptions}
                        label='Store Management'
                        isMulti
                        {...rest}
                        {...input}
                      />
                    )}
                  </Field>

                  <Field name='states'>
                    {({ input, ...rest }) => (
                      <SelectAdapter
                        options={stateOptions}
                        placeholder='select one or more state'
                        label='Select by state'
                        isMulti
                        {...rest}
                        {...input}
                      />
                    )}
                  </Field>

                  <Field name='cities'>
                    {({ input, ...rest }) => (
                      <SelectAdapter
                        options={cityOptions}
                        label='Select by city'
                        placeholder='select one or more city'
                        isMulti
                        {...rest}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className='permission-section'>
                <div className='permission-section__header'>
                  <span>permissions</span>
                  <span>(select 1 or more options)</span>
                </div>
                <div className='permission-section__content'>
                  {permissions?.map((permission) => (
                    <CheckBox
                      key={permission.codename}
                      checked={adminPermissionsCodes?.includes(
                        permission.codename
                      )}
                      onChange={(e) => handlePermissionChange(e, permission)}
                    >
                      {permission.name}
                    </CheckBox>
                  ))}
                  {/* <CheckBox key='1001-super'>Grant All Access</CheckBox> */}
                </div>
              </div>

              <div className='actions-section'>
                <OutlineButton
                  icon='save-outline'
                  onClick={handleSubmit}
                  loading={submitting}
                >
                  Update
                </OutlineButton>

                <OutlineButton
                  icon='trash-outline'
                  onClick={handleDelete}
                  loading={submitting}
                >
                  Delete
                </OutlineButton>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default UserAdminDetailContainer;
