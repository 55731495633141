import {Route, Routes} from 'react-router-dom'
import UserPage from '../pages/user'
import { 
  UserDashboard,
  UserAdminAddContainer,
  UserAdminDetailContainer,
  UserClientAddContainer,
  UserClientDetailContainer,
  UserAddContainer, 
  UserListContainer
} from '../containers/user';

const UserRoutes = () => (
  <Routes>
    <Route path="" element={<UserPage />} >
      <Route index element={<UserDashboard /> } />
      <Route path="list/" element={<UserListContainer />} />
      <Route path="add/" element={<UserAddContainer />}>
        <Route path="admin/" element={<UserAdminAddContainer /> } />
        <Route path="client/" element={<UserClientAddContainer /> } />
      </Route>
      <Route path="admin/:userId" element={<UserAdminDetailContainer /> } />
      <Route path="client/:userId" element={<UserClientDetailContainer /> } />
    </Route>
  </Routes>
)

export default UserRoutes