import {useSelector} from 'react-redux'

export const useSTWWheels = () => {
  return useSelector(state => state.stw.wheels)
}

export const useSTWWheel = (wheelId) => {
  const wheels = useSTWWheels()
  return wheels?.find(wheel => wheel.id === wheelId)
}

export const useClientWheels = (clientId) => {
  const wheels = useSTWWheels()
  return wheels?.filter(wheel => wheel.owner === clientId)
}

export const useStoreWheel = (storeId) => {
  const wheels = useSTWWheels()
  return wheels?.find(wheel => wheel.store === storeId)
}