import { useStoreStatOverview } from "../../../hooks/ai-stat";
import { useStoreDevices } from "../../../hooks/device";
import StatBox from "../stat-box";

const StoreChartOverView = ({ storeId }) => {
  const data = useStoreStatOverview(storeId);
  const storeDevices = useStoreDevices(storeId);
  return (
    <div className="chart-overview">
      <StatBox value={data?.total_today ?? 0} label="total today" />
      <StatBox value={data?.total_week ?? 0} label="total This Week" />
      <StatBox value={data?.ath ?? 0} label="All Time High" />
      <StatBox value={storeDevices?.length ?? 0} label="Total Devices" />
    </div>
  );
};

export default StoreChartOverView;
