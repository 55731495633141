import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AuthWrapper from "./authWrapper";
import OuterWrapper from "./OuterWrapper";
import { useLoggedIn } from "../../hooks/auth";
import WrapperLoader from "./WrapperLoader";

import { fetchVideos } from "../../states/video";
import { fetchMachines } from "../../states/device";
import { fetchStores } from "../../states/store";
import { fetchActions, fetchDays, fetchSchedules } from "../../states/schedule";
import { fetchSurveyLeads, fetchWinnerLeads, fetchQR } from "../../states/stat";
import { fetchUser, setLoggedIn } from "../../states/auth";
import { fetchClients } from "../../states/client";
import { fetchPhotoBooth } from "../../states/photobooth";
import { fetchGallery } from "../../states/gallery";
import { fetchWheels } from "../../states/stw";
// import { useQuery } from "react-query";
import axiosInstance from "../../utils/axios";

const MainWrapper = ({ children }) => {
  /*
    MainWrapper houses the loader, providers and other global state
    and shows the Routes after that
  */

  // const { data: devices } = useQuery(
  //   ["devices"],
  //   async () => {
  //     axiosInstance.get("machines/");
  //   },
  //   {
  //     refetchInterval: 5_000,
  //   }
  // );

  // console.log("devices: ", devices);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = useLoggedIn();

  const fetchAllData = async () => {
    // how do we handle a case where some of the data retrieval failed ?
    // how do we handle a case where connection is lost
    // got to use unwrap so we can return a promise hence use await
    // how about chaining them
    await Promise.all([
      dispatch(fetchMachines()).unwrap(),
      dispatch(fetchStores()).unwrap(),
      dispatch(fetchClients()).unwrap(),
      dispatch(fetchSchedules()).unwrap(),
      dispatch(fetchVideos()).unwrap(),
      dispatch(fetchDays()).unwrap(),
      dispatch(fetchActions()).unwrap(),
      dispatch(fetchQR()).unwrap(),
      dispatch(fetchSurveyLeads()).unwrap(),
      dispatch(fetchWinnerLeads()).unwrap(),
      dispatch(fetchUser()).unwrap(),
      dispatch(fetchPhotoBooth()).unwrap(),
      dispatch(fetchWheels()).unwrap(),
    ]);

    try {
      await dispatch(fetchGallery()).unwrap()
    } catch (e) {
      console.error('galler fetch failed, ')
    }
  };

  useEffect(() => {
    async function f() {
      if (isLoggedIn) {
        setIsLoading(true);
        console.log("fetching all data !, logged In", isLoggedIn);
        await fetchAllData();
        setIsLoading(false);
      }
    }
    f();
  }, [isLoggedIn]);

  // authwrapper makes sure we're logged in before showing any of it's children
  // sidebar and other component
  // if not, it redirects to login and remembers where were last
  // outer wrapper shows the side bar and other components
  return (
    <AuthWrapper>
      {isLoading ? <WrapperLoader /> : <OuterWrapper />}
    </AuthWrapper>
  );
};

export default MainWrapper;
