import {Form, Field} from 'react-final-form'
import Button from '../button'

const LoginForm = ({handleSubmit, loading=false}) => {
  return (
    <div className="login-form">
      <Form 
        onSubmit={handleSubmit} 
        validate={(values) => {
          const errors = {}
          if (!values.username)
            errors.username = 'Username is Required'

          if (!values.password) 
            errors.password = 'Password is definitely required'
        
          return errors
        }}
      >
        {
          ({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <div className="login-form__group">
                <label>Username</label>
                <Field name="username">
                  { ({input, meta}) => (
                    <div className="login-form__group-inner">
                      <input {...input}  />
                      {meta.error && meta.touched && <span className="login-form__group-error">{meta.error}</span>}
                    </div> 
                  )}
                </Field>
              </div>

              <div className="login-form__group">
                <label>Password</label>
                <Field name="password" type="password">
                  { ({input, meta}) => (
                    <div className="login-form__group-inner">
                      <input {...input}  />
                      {meta.error && meta.touched && <span className="login-form__group-error">{meta.error}</span>}
                    </div> 
                  )}
                </Field>
              </div>

              <div className="login-form__actions">
                <Button onClick={handleSubmit} loading={loading}>Login</Button>
              </div>
            </form>
          )
        }
      </Form>
    </div>
  )
}

export default LoginForm