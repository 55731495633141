const SearchFilter = ({handleOnChange, value}) => {
  return (
    <div className="search-filter">
      <input 
        type="text" 
        placeholder="search ..." 
        onChange={handleOnChange} 
        value={value}
      />
      <ion-icon name="search-outline"></ion-icon>
    </div>
  )
}

export default SearchFilter