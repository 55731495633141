import React from 'react';
import {Form} from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import UserAddHeader from './UserAddHeader';
import InputField from './components/InputField';
import OutlineButton from '../../components/button/OutlineButton';
import { createAdminUser } from '../../states/auth';
import {useNotification} from '../../hooks';

const UserAdminAddContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { showNotificationError, showNotificationSuccess} = useNotification()
  
  const handleSubmit = async (values) => {
    try {
      const adminUser = await dispatch(createAdminUser(values)).unwrap()
      showNotificationSuccess('User Admin Created')
      navigate(`/users/admin/${adminUser.id}`)
    } catch ( userCreateError ) {
      console.error(userCreateError)
      showNotificationError('Creation Failed')
    }
  }
  
  return (
    <div className='user-add-container'>
      <div className="user-add-container__header">
        <UserAddHeader title="Admin"/>   
      </div>
      <div className="user-add-container__content">
        <Form onSubmit={handleSubmit}>
        {({handleSubmit, values}) => (
          <>
          <div className="contact-section">
            <InputField 
              label="username" 
              placeholder="This will be used to login"
              name="username"
            />
            <InputField label="password" name="password" />
            <InputField label="First Name" name="first_name" />
            <InputField label="Last Name" name="last_name" />
            <InputField label="Email Address" name="email" />
            <InputField 
              name="phone"
              label="Cell Number" 
              placeholder="Number with availability required" />
          </div>

          <div className="actions-section">
            <OutlineButton icon="save-outline" onClick={handleSubmit}>Create User</OutlineButton>
          </div>
          </>
        )}
        </Form>
      </div>
    </div>
  );
};

export default UserAdminAddContainer;