const ClientScheduleItem = ({schedule, icon, handleItemDelete}) => {
  return (
    <div className="schedule-item">
      <div className="icon"><ion-icon name={icon}></ion-icon></div>
      <div className="detail">
        <span className="time">{schedule.time}</span>
        <span className="interval">{schedule?.days_name[0]}</span>
      </div>
      <div className="actions">
        <span onClick={e => handleItemDelete(schedule)}><ion-icon name="trash"></ion-icon></span>
        {/* <span><ion-icon name="create-outline"></ion-icon></span> */}
      </div>
    </div>
  )
}

export default ClientScheduleItem