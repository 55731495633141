import React from 'react';
import {useNavigate} from 'react-router-dom';
import DateTimeBox from '../date/DateTimeBox'
import { useDevice } from '../../hooks/device';
import { useStore } from '../../hooks/store';

const DeviceCard = ({device}) => {
  const navigate = useNavigate()
  const {name, id: deviceId, store: storeId, created} = device
  const deviceStore = useStore(storeId)

  return (
    <div className='device-card' onClick={e => navigate(`/devices/${deviceId}`)}>
      <div className="device-card__header">
        <div className="device-card__header-title">{name}</div>
        <DateTimeBox dateStr={created} />
      </div>
      <div className="device-card__location">
        <span className='label'>location</span>
        <span className='text'>{storeId ? deviceStore?.name : 'N/A'}</span>
      </div>

      <div className="device-card__address">
        <span className='icon'><ion-icon name="location-outline"></ion-icon></span>
        <span className='text'>
          {storeId ? `${deviceStore?.location?.address}, ${deviceStore?.location?.city}, ${deviceStore?.location?.state}, ${deviceStore?.location?.country}`
            : 'N/A'}
        </span>
      </div>
    </div>
  );
};

export default DeviceCard;