import {useSelector} from 'react-redux'

// stat
export const useStatQRCount = () => {
  return useSelector(state => state.stat.qr)?.length
}

export const useStatQRCountThisWeek = () => {
  return useSelector(state => state.stat.qrThisWeek)?.length
}

export const useSurveyLeads = () => {
  return useSelector(state => state.stat.surveyLeads)
}

export const useWinnerLeads = () => {
  return useSelector(state => state.stat.winnerLeads)
}

export const useLeads = () => {
  const surveyLeads = useSurveyLeads()
  const winnerLeads = useWinnerLeads()
  return { surveyLeads, winnerLeads }
}