import axios from 'axios'
import { getLocalAccessToken, getLocalRefreshToken, updateLocalAccessToken } from './token'
import { API_SERVER } from '../config'

export const endpoint = ( ep ) => {
  return `${API_SERVER}${ep}`
}

export const axiosInstanceCreate = (options = {}, headers = { "Content-Type": "application/json" }) => {
  return axios.create({
    baseURL: endpoint(''),
    headers,
    ...options
  })
}

export const axiosInstance = axiosInstanceCreate()

axiosInstance.interceptors.request.use(
  (config) => {
    console.log('including access token .. ')
    const token = getLocalAccessToken() // what if there's no token 
    config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  (error) => {
    return Promise.reject(error);
  }
)

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    /*
      check if it's not a token request so we don't go in infinite loop
      also we make sure we only ask for refresh if it's an unauthorized response
      and we don't already retried before
    */
    if (!originalConfig.url.includes("/api/auth/token") && err.response) { 
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true; // mark as retried 
        const refresh = getLocalRefreshToken()
        if (!!refresh) { 
          try {
            const response = await axios.post((`${endpoint('')}auth/token/refresh/`), { refresh, });
            updateLocalAccessToken(response.data?.access); // update the Local storage 
            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
    }
    return Promise.reject(err);
  }
)

export const makeRequest = async (url) => {
  const {data} = await axiosInstance.get(url);
  return data;
}

export default axiosInstance
export const axiosInstanceWithBlob = axiosInstanceCreate({ responseType: 'blob' })

