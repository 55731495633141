import { useSelector } from "react-redux"

// auth
export const useAuth = () => {
  return useSelector(state => state.auth)
}

export const useLoggedIn = () => {
  return useSelector(state => state.auth.isLoggedIn)
}

export const useUser = () => {
  const auth = useAuth()
  return auth?.user
}

export const useIsSuperUser = () => {
  return useSelector(state => state.auth.user?.is_superuser)
}

export const useAdminUsers = () => {
  const auth = useAuth()
  return auth?.adminUsers
}

export const useAdminUser = (userId) => {
  const adminUsers = useAdminUsers()
  return adminUsers?.find( user => user.id === parseInt( userId) )
}

export const useAdminPermissions = () => {
  const adminPermissions = useSelector(state => state.auth.adminPermissions)
  return adminPermissions
}

export const useClientUsers = () => {
  const auth = useAuth()
  return auth?.clientUsers
}

export const useClientUser = (userId) => {
  const clientUsers = useClientUsers()
  return clientUsers?.find( user => user.id === parseInt( userId) )
}

export const useCurrentClientUser = () => {
  const user = useUser()
  // console.log()
  return useClientUser(user?.id)
}
