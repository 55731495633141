import { useState } from "react";
import { useLocation } from "react-router-dom";
import SortButton from "../../components/SortButton";
import SearchFilter from "../../components/SearchFilter";
import DeviceCard from "../../components/device/DeviceCard";
import SortBox from "../../components/SortBox";
import { useDevices } from "../../hooks/device";
import { isMachineOffline } from "../../utils";
import { useDevicesQuery } from "../../hooks/queries";

const DeviceListContainer = () => {
  const { data: devices } = useDevicesQuery();
  const [filterValue, setFilterValue] = useState("");
  const { hash } = useLocation();

  const filterDevices = (devices) => {
    if (hash === "#all") return devices;
    else if (hash === "#pending")
      return devices?.filter((device) => device?.store === null);
    else if (hash === "#offline")
      return devices?.filter((device) => isMachineOffline(device.seen));
    else if (hash === "#online")
      return devices?.filter((device) => !isMachineOffline(device.seen));
  };

  return (
    <div className='device-list-container'>
      <div className='device-list-container__header'>
        <SearchFilter
          handleOnChange={(e) => setFilterValue(e.target.value)}
          value={filterValue}
        />
      </div>

      <div className='device-list-container__header-sort'>
        <SortBox> Vabe ID</SortBox>
        <SortBox>Registration Date </SortBox>
      </div>

      <div className='device-list-container__content'>
        {devices &&
          filterDevices(devices)
            ?.filter((device) =>
              device.name.toLowerCase().includes(filterValue.toLowerCase())
            )
            .map((device) => <DeviceCard key={device.id} device={device} />)}
      </div>
    </div>
  );
};

export default DeviceListContainer;
