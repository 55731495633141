import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { useDeviceStat } from "../../../hooks/ai-stat";

import ChartContainer from "./chart-container";
import {
  transformDeviceMonthData,
  transformDeviceWeekData,
  transformDeviceDayData,
} from "../transformers";

export const GenericDeviceChart = ({ data }) => {
  // [ {name: , value: }, ...]
  return (
    <div className="generic-chart">
      <ResponsiveContainer
        width="100%"
        height={350}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <AreaChart width={800} height={350} data={data}>
          <Area type="monotone" dataKey="value" stroke="#8884d8" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

const DeviceDayChart = ({ deviceId, intervalValue }) => {
  const { data, loading } = useDeviceStat(deviceId, "day", intervalValue);
  if (loading) return <p>Loading ...</p>;
  if (!data) return <p>Unable To load chart data</p>;

  const _dataList = transformDeviceDayData(data);

  return (
    <div className="chart">
      <GenericDeviceChart data={_dataList} />
    </div>
  );
};

const DeviceWeekChart = ({ deviceId }) => {
  const { data, loading } = useDeviceStat(deviceId, "week");
  if (loading) return <p>Loading ...</p>;
  if (!data) return <p>Unable To load chart data</p>;

  const _dataList = transformDeviceWeekData(data);
  return <GenericDeviceChart data={_dataList} />;
};

const DeviceMonthChart = ({ deviceId }) => {
  const { data, loading } = useDeviceStat(deviceId, "month");
  if (loading) return <p>Loading ...</p>;
  if (!data) return <p>Unable To load chart data</p>;

  const _dataList = transformDeviceMonthData(data);
  return <GenericDeviceChart data={_dataList} />;
};

export const DeviceChartContainer = ({ deviceId }) => {
  const charts = {
    today: DeviceDayChart,
    week: DeviceWeekChart,
    month: DeviceMonthChart,
  };
  return <ChartContainer charts={charts} otherProps={{ deviceId }} />;
};
