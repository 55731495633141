import React from 'react';
import classNames from 'classnames';

const ClientStoreItem = ({store, properties, onClick}) => {
  const {location ,name} = store
  return (
    <div className="client-store-item">
      <div className="store__title" onClick={onClick}>{name}</div>
      <div className="store__address">
        { location ? `${location.address}, ${location.city}, ${location.state}, ${location.country}` : 'N/A'}
      </div>
      <div className="store__actions">
        <span className={classNames('store__actions-icon', {active: properties.includes('video')})}>
          <ion-icon name="play"></ion-icon>
        </span>
        <span className={classNames('store__actions-icon', {active: properties.includes('photo')})}>
          <ion-icon name="camera-outline"></ion-icon>
        </span>
        <span className={classNames('store__actions-icon', {active: properties.includes('wheel')})}>
          <ion-icon name="pie-chart-outline"></ion-icon>
        </span>
      </div>
    </div>
  );
};

export default ClientStoreItem;