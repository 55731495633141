const ReportItem = ({primary, secondary, value, max}) => {
  return (
    <div className="item">
      <div className="item-top">
        <span className='primary'>{primary}</span>
        {secondary && <span className='secondary'>{secondary}</span> }
      </div>
      <div className="item-bottom">
        <progress max={max} value={value}>{value}</progress>
        <span>{value}</span>
      </div>
  </div>
  )
}

export default ReportItem