import { useSelector } from "react-redux"

export const useSchedules = () => {
  return useSelector(state => state.schedule.schedules)
}

export const useDeviceSchedules = (machineId) => {
  const schedules = useSchedules()
  return schedules?.filter(schedule => schedule.machine === machineId)
}

export const useDays = () => {
  return useSelector(state => state.schedule.days)
}

export const useActions = () => {
  return useSelector(state => state.schedule.actions)
}

