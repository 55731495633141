export const isMachineOffline = (seen) => {
  let now = new Date().getTime()/1000
  return (now - seen) > 300 // 5 mins
}

export const convertTTT = (timeStr) => {
  const digits = timeStr.split(':').map(n => Number(n))
  const hours = digits[0] * 60 * 60
  const minutes = digits[1] * 60
  const seconds = digits[2]
  return Number(hours + minutes + seconds)
}