import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../utils/axios'

export const fetchQR = createAsyncThunk('stats/fetchQR', async (data, thunkAPI) => {
    const result = await axiosInstance.get(`qr/`)
    console.log(result.data)
    return result.data
  }
)

export const fetchQRThisWeek = createAsyncThunk('stats/fetchQRThisWeek', async (data, thunkAPI) => {
    const result = await axiosInstance.get(`qr/thisweek/`)
    console.log('qr this week', result)
    return result.data
  }
)

export const fetchSurveyLeads = createAsyncThunk('stats/surveyLeads', async (data, thunkAPI) => {
    const result = await axiosInstance.get("mailchimp/83451337aa/")
    console.log('Survey Data: ', result.data)
    return result.data
  }
)

// CRM version , we going to have the server version as well 
export const fetchWinnerLeads = createAsyncThunk('stats/winnerLeads',async (data, thunkAPI) => {
    const result = await axiosInstance.get("mailchimp/4cb41a1ff3/")
    console.log('winner Data', result.data)
    return result.data
  }
)


const statSlice = createSlice({
  name: 'stat',
  initialState: {
    qr: null,
    qrThisWeek: null,
    qrCount: null,
    surveyLeads: null,
    winnerLeads: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQR.fulfilled, (state, action) => {
      state.qr = action.payload
    }).addCase(fetchQRThisWeek.fulfilled, (state, action) => {
        state.qrThisWeek = action.payload
      })
      .addCase(fetchSurveyLeads.fulfilled, (state, action) => {
        state.surveyLeads = action.payload?.stats?.total_contacts
      })
      .addCase(fetchWinnerLeads.fulfilled, (state, action) => {
        state.winnerLeads = action.payload?.stats?.total_contacts
      })
  }
})

export default statSlice.reducer