import {Route, Routes} from 'react-router-dom';
import StorePage from '../pages/store'
import {
  StoreDashboard, 
  StoreDetailContainer, 
  StoreListContainer, 
  StoreCreateContainer
} from '../containers/store';

const StoreRoutes = () => (
  <Routes>
    <Route path='' element={<StorePage />}>
      <Route index element={<StoreDashboard />} />
      <Route path="list/" element={<StoreListContainer />} />
      <Route path="create/" element={<StoreCreateContainer />} />
      <Route path=":storeId/" element={<StoreDetailContainer />} />
    </Route>
  </Routes>
)

export default StoreRoutes