import React, { useMemo } from 'react';
import {useQuery} from 'react-query'
import { makeRequest } from '../../utils/axios'; 
import DashBox from '../../components/dashbox';
import TopReportBox from '../../components/report/TopReportBox';

const ReportPhotosContainer = () => {
  const  {data} = useQuery('g-gallery', () => 
  makeRequest(`report/gallery/`));
  const MAXI = useMemo( () => Math.max(...Object.values(data?.clients??{}))+100, [data] );

  return (
    <div className='report-photos-container'>
      <div className="header-box">
        <DashBox label="Photos Total" value={data?.total} />
      </div>
      <div className="content">
        {data && Object.entries(data?.clients).map( ([client, value], k) => (
           <TopReportBox 
            key={k}
            data={[{primary: 'Total', value: value, max: MAXI }]} 
            title={client}
          />
        ))}
      </div>
    </div>
  );
};

export default ReportPhotosContainer;