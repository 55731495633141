import React, { useState } from "react";
import Button from "../../../components/button";

const ChartContainer = ({ charts, otherProps }) => {
  const [currentChart, setCurrentChart] = useState("today");
  const [intervalValue, setInternalValue] = useState();

  const _handleCustomDaySelect = (e) => {
    const value = e.target.value;
    console.log("_handleCustomDaySelect: ", value);
    setInternalValue(value);
    setCurrentChart("today");
  };

  const _handleCustomMonthSelect = (e) => {
    console.log(e.target.value);
    const value = e.target.value;
    setInternalValue(value);
    setCurrentChart("month");
  };

  const _handleTodaySelect = (e) => {
    const today_string = new Date().toISOString().split("T")[0];
    setInternalValue(today_string);
    setCurrentChart("today");
  };

  return (
    <div className="chart-container">
      <div className="chart-container__renderer">
        {React.createElement(charts[currentChart], {
          intervalValue,
          ...otherProps,
        })}
      </div>
      <div className="chart-container__interval-toolbar">
        <Button onClick={_handleTodaySelect}>Today</Button>
        <Button onClick={() => setCurrentChart("week")}>This week</Button>
        <Button onClick={() => setCurrentChart("month")}>This Month</Button>
        <Button>Custom Day</Button>
        <input type="date" onChange={_handleCustomDaySelect} />
        <Button>Custom Month</Button>
        <input type="month" onChange={_handleCustomMonthSelect} />
      </div>
    </div>
  );
};

export default ChartContainer;
