import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification, useConfirmation } from "../../hooks";
import { useStore } from "../../hooks/store";
import { useStoreDevices } from "../../hooks/device";
import StoreCreateForm from "../../components/store/StoreCreateForm";
import StatusPill from "../../components/status/StatusPill";
import DateTimeBox from "../../components/date/DateTimeBox";
import DeviceCard from "../../components/device/DeviceCard";
import { deleteStore, updateStore } from "../../states/store";
import Button from "../../components/button";
import axiosInstance from "../../utils/axios";

const StoreDetailContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showNotificationSuccess, showNotificationError } = useNotification();
  const { confirmAction } = useConfirmation();
  const store = useStore(params.storeId);
  const [editMode, setEditMode] = useState(false);
  const devices = useStoreDevices(params.storeId);

  const [storeRadius, setStoreRadius] = useState(700);

  const _updateRadius = async () => {
    console.log('updating radius ..');
    try {
      const data = await axiosInstance.post(`stores/${params.storeId}/radius/`, { radius: storeRadius });
      console.log('radius response: ', data)
      showNotificationSuccess('Radius Updated');
    } catch (radiusUpdateError) {
      console.error(radiusUpdateError)
      showNotificationError('Update Error');
    }
  }

  const handleStoreUpdate = async (values) => {
    console.log("updating ... ", values);
    const {
      location: { address, state, country, city },
      ...rest
    } = values;
    const location = {
      address,
      country: country?.value,
      state: state?.value,
      city: city?.value,
    };
    try {
      await dispatch(
        updateStore({ id: params.storeId, ...rest, location })
      ).unwrap();
      showNotificationSuccess("Update Success", "");
      setEditMode(false);
    } catch (deleteError) {
      console.error(deleteError);
      showNotificationError("Update Error", "");
    }
  };

  const handleStoreDelete = async () => {
    const response = await confirmAction(
      "Are you sure you want to delete store ? "
    );
    if (response) {
      try {
        await dispatch(deleteStore(params.storeId)).unwrap();
        showNotificationSuccess("Delete Success", "");
        navigate("/stores/list"); // redirect to the list
      } catch (deleteError) {
        console.error(deleteError);
        showNotificationError("Delete Error", "");
      }
    }
  };

  return (
    <div className='store-detail-container'>
      <div className='store-detail-container__header'>
        <div className='header--left'>
          <h2>{store?.name} </h2>
          <div>
            <span onClick={(e) => setEditMode((p) => !p)}>
              <ion-icon name='create-outline'></ion-icon>
            </span>
            <span onClick={handleStoreDelete}>
              <ion-icon name='trash-outline'></ion-icon>
            </span>
          </div>
        </div>

        <div className='header--right'>
          <StatusPill success={true} label='active' />
          <DateTimeBox />
        </div>
      </div>
      <div className='store-detail-container__content'>
        <StoreCreateForm
          handleSubmit={handleStoreUpdate}
          initialValues={{
            ...store,
            location: {
              ...store?.location,
              country: {
                label: store?.location?.country,
                value: store?.location?.country,
              },
              state: {
                label: store?.location?.state,
                value: store?.location?.state,
              },
              city: {
                label: store?.location?.city,
                value: store?.location?.city,
              },
            },
          }}
          editMode={editMode}
        />
      </div>

      <div className="radius-update-field" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <label style={{ fontSize: '18px', lineHeight: '30px' }}>Enter radius in ft</label><input type="number" style={{ width: '50px', height: '30px' }} step={100} value={storeRadius} onChange={e => setStoreRadius(e.target.value)} />
        <Button style={{ boxShadow: 'none' }} onClick={_updateRadius}>Update Radius</Button>
      </div>

      <div className='store-detail-container__devices'>
        <span>STORE DEVICES</span>
        <div className='content'>
          {devices?.map((device) => (
            <DeviceCard device={device} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StoreDetailContainer;
