import React from "react";
import { useNavigate } from "react-router-dom";
import DeviceDashBox from "../../components/device/DashBox";
import { useQuery } from "react-query";
import axiosInstance from "../../utils/axios";
import { useMemo } from "react";
import { isMachineOffline } from "../../utils";
import { useDevicesQuery } from "../../hooks/queries";

const DeviceDashboard = () => {
  const navigate = useNavigate();

  const { data: devices } = useDevicesQuery({
    refetchInterval: 10_000,
    refetchOnMount: true,
  });

  const onlineDevices = useMemo(
    () => devices?.filter((m) => !isMachineOffline(m.seen))?.length ?? 0,
    [devices]
  );

  const offlineDevices = useMemo(
    () => devices?.filter((m) => isMachineOffline(m.seen)).length ?? 0,
    [devices]
  );

  const pendingDevices = useMemo(
    () => devices?.filter((m) => m.store === null).length ?? 0,
    [devices]
  );

  return (
    <div className='device-dashboard'>
      <DeviceDashBox
        labelPrimary='Total'
        labelSecondary='All Devices'
        labelValue={devices?.length}
        onClick={(e) => navigate("list/#all")}
      />
      <DeviceDashBox
        labelPrimary='New'
        labelSecondary='Active Pending'
        labelValue={pendingDevices}
        onClick={(e) => navigate("list/#pending")}
      />
      <DeviceDashBox
        labelPrimary='Offline'
        labelSecondary='Non-Active'
        labelValue={offlineDevices}
        onClick={(e) => navigate("list/#offline")}
      />
      <DeviceDashBox
        labelPrimary='Online'
        labelSecondary='Active'
        labelValue={onlineDevices}
        onClick={(e) => navigate("list/#online")}
      />
    </div>
  );
};

export default DeviceDashboard;
