const TextArea = ({children, ...inputProps}) => {
  return ( 
    <textarea 
      className="textarea-field"
      {...inputProps}
    >
      {children}
    </textarea>
  )
}

export default TextArea