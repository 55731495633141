import React from 'react';
import SortBox from '../../components/SortBox';
import DashBox from '../../components/dashbox';
import TopReportBox from '../../components/report/TopReportBox';

const ClientReportScanContainer = () => {
  const data  =  [
    {
      primary: 'Walmart', 
      secondary: '3352 Los Angeles California',
      value: 235, 
      max: 500
    },
    {
      primary: 'Walmart', 
      secondary: '3352 Los Angeles California',
      value: 405, 
      max: 500
    },
    {
      primary: 'Walmart', 
      secondary: '3352 Los Angeles California',
      value: 325, 
      max: 500
    },
    {
      primary: 'Walmart', 
      secondary: '3352 Los Angeles California',
      value: 235, 
      max: 500
    },
    {
      primary: 'Walmart', 
      secondary: '3352 Los Angeles California',
      value: 105, 
      max: 500
    }
  ]
 
  return (
    <div className='report-scan-container'>
      <div className="report-scan-container__header">
        <div className="report-scan-container__header-left">
          <SortBox>Date</SortBox>
          <SortBox>City</SortBox>
          <SortBox>State</SortBox>
        </div>
        <div className="report-scan-container__header-right">
          <span>CSV | Download</span>
          <span><ion-icon name="document-outline"></ion-icon></span>
        </div>
      </div>
      <div className="report-scan-container__content">
        <DashBox label="scans" value={123} secondary="Total" />
        <TopReportBox data={data} />
      </div>
    </div>
  );
};

export default ClientReportScanContainer;