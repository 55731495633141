import React from 'react';

/* 
This will cater for both the admin user and the client user
*/

const UserCard = ({user, onClick}) => {
  if (user.isClient) return (
    <div className='user-card' onClick={onClick}>
      <span>{user.isClient && 'CLIENT'} </span>
      <p>username: {user?.user.username}</p>
    </div>
  );

  if (user.isAdmin) return (
    <div className='user-card' onClick={onClick}>
      <span> {user.isAdmin && 'ADMIN'} </span>
      <p>username: {user?.user.username}</p>
    </div>
  );
};

export default UserCard;