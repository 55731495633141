import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../utils/axios'

export const fetchMachines = createAsyncThunk('machine/fetchMachines', async (data, thunkAPI) => {
  const result = await axiosInstance.get('machines/')
  return result.data
}
)

export const updateMachine = createAsyncThunk('machine/updateMachine', async (data, thunkAPI) => {
  const { machineId, ...machineData } = data
  const result = await axiosInstance.patch(`machines/${machineId}/`, machineData)
  return result.data
}
)

export const deleteMachine = createAsyncThunk('machine/deleteMachine', async (machineId, thunkAPI) => {
  await axiosInstance.delete(`machines/${machineId}/`)
  return machineId
}
)

export const updateAllSeen = createAsyncThunk('machine/updateAllSeen', async (data, thunkAPI) => {
  const result = await axiosInstance.get(`machines/seen/`)
  return result.data
}
)

/* i think ths is supposed to be under the machine reducer and not here DEBUG */
export const setMachineAttended = createAsyncThunk('photobooth/setMachineAttended', async (data) => {
  const { machineId } = data
  const result = await axiosInstance.post(`booth/${machineId}/done/`)
  return result.data // returns the new state of the booth status
}
)



const machineSlice = createSlice({
  name: 'machine',
  initialState: { devices: null },
  reducers: {
    setMachines(state, action) {
      state.devices = action.payload
    },
    updateMachineSeen(state, action) {
      state.devices.find(m => m.id === action.payload[0]).seen = action.payload[1]
    },
    setBoothStatus(state, { payload: { machineId, status } }) {
      state.devices.find(m => m.id === machineId).booth_status = status
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMachines.fulfilled, (state, action) => {
      state.devices = action.payload
    }).addCase(updateMachine.fulfilled, (state, action) => {
      state.devices = state.devices.map(m => m.id === action.payload.id ? action.payload : m)
    }).addCase(deleteMachine.fulfilled, (state, action) => {
      state.devices = state.devices.filter(s => s.id !== action.payload)
    }).addCase(setMachineAttended.fulfilled, (state, { payload: { machineId, status } }) => {
      state.devices.find(m => m.id === machineId).booth_status = status
    }).addCase(updateAllSeen.fulfilled, (state, action) => {
      state.devices = state.devices.map(device => ({ ...device, seen: action.payload[device.id] }))
    })
  }
})

export const { setMachines, setBoothStatus } = machineSlice.actions
export default machineSlice.reducer
