import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from '../../utils/axios'

export const fetchWheels = createAsyncThunk('STW/fetch', async (data, thunkAPI) => {
    const result = await axiosInstance.get('stw/')
    return result.data
  }
)

export const createWheel = createAsyncThunk('STW/create',
  async (formData, thunkAPI) => {    
    const result = await axiosInstance.post('stw/', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return result.data
  }
)

export const updateWheel = createAsyncThunk('STW/update', 
  async ({wheelId, formData}) => {
    const result = await axiosInstance.patch(`stw/${wheelId}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return result.data
  }
)

export const deleteWheel = createAsyncThunk('STW/delete', 
  async ({ wheelId }, thunkAPI) => {
    await axiosInstance.delete(`stw/${wheelId}/`)
    return wheelId
  }
)

export const createWheelPie = createAsyncThunk('STW/createPie',
  async (formData, thunkAPI) => {
    const result = await axiosInstance.post('stwpie/', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return result.data
  }
)

export const deleteWheelPie = createAsyncThunk('STW/deletePie',
  async ({ pieId, stwId }, thunkAPI) => {
    const result = await axiosInstance.delete(`stwpie/${pieId}/`)
    console.log('pie delete data: ', result.data)
    return { pieId, stwId }
  }
)


export const updateWheelPie = createAsyncThunk('STW/updatePie',
  async ({pieId, formData}, thunkAPI) => {
    const result = await axiosInstance.patch(`stwpie/${pieId}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return result.data
  }
)


const STWSlice = createSlice({
  name: 'STW',
  initialState: { wheels: null },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWheels.fulfilled, (state, action) => {
      state.wheels = action.payload
    })
      .addCase(createWheel.fulfilled, (state, action) => {
        state.wheels = state.wheels.concat(action.payload)
      })
      .addCase(deleteWheel.fulfilled, (state, action) => {
        state.wheels = state.wheels.filter(w => w.id !== action.payload)
      })
      .addCase(updateWheel.fulfilled, (state, action) => {
        state.wheels = state.wheels.map(w => w.id === action.payload.id ? action.payload : w)
      })
      .addCase(createWheelPie.fulfilled, (state, action) => {
        state.wheels = state.wheels.map(w => w.id === action.payload.stw ? { ...w, pies: w.pies.concat(action.payload) } : w)
      })
      .addCase(updateWheelPie.fulfilled, (state, action) => {
        state.wheels = state.wheels.map(w => w.id === action.payload.stw ? { ...w, pies: w.pies.map(p => p.id === action.payload.id ? action.payload : p) } : w)
      })
      .addCase(deleteWheelPie.fulfilled, (state, action) => {
        state.wheels = state.wheels.map(w => w.id === action.payload.stwId ? { ...w, pies: w.pies.filter(p => p.id !== action.payload.pieId) } : w)
      })
  }
})

// export const { } = STWSlice.actions
export default STWSlice.reducer