import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import SortBox from "../../components/SortBox";
import { makeRequest } from "../../utils/axios";
import {
  getClientSurveyReportUrl,
  getClientWinnerReportUrl,
} from "../../utils/report";

const LeadDashBox = ({ title, value, csvDownloadUrl }) => {
  return (
    <div className='lead-dash-box'>
      <div className='lead-dash-box__header'>
        <div className='title'>
          <span className='primary'>{title}</span>
          <span className='secondary'>Total</span>
        </div>
        <div className='actions'>
          <span>
            {" "}
            <a href={csvDownloadUrl}> CSV | Download </a>
          </span>
          <span>
            <ion-icon name='document-outline'></ion-icon>
          </span>
        </div>
      </div>
      <div className='lead-dash-box__content'>{value}</div>
    </div>
  );
};

const ClientReportLeadContainer = () => {
  const params = useParams();
  const { data } = useQuery("leads", () =>
    makeRequest(`report/${params.clientId}/leads/`)
  );

  return (
    <div className='report-lead-container'>
      <div className='report-lead-container__header'>
        <div className='report-lead-container__header-left'>
          {/* <SortBox>Date</SortBox>
          <SortBox>City</SortBox>
          <SortBox>State</SortBox> */}
        </div>
      </div>
      <div className='report-lead-container__content'>
        <LeadDashBox
          title='Survey Leads'
          value={data?.survey_lead_total}
          csvDownloadUrl={getClientSurveyReportUrl(params.clientId)}
        />
        <LeadDashBox
          title='Winner Leads'
          value={data?.winner_lead_total}
          csvDownloadUrl={getClientWinnerReportUrl(params.clientId)}
        />
      </div>
    </div>
  );
};

export default ClientReportLeadContainer;
