import React from 'react';
import { useNavigate } from 'react-router-dom';
import StoreDashBox from '../../components/store/DashBox';
import {useStores} from '../../hooks/store';

const StoreDashboard = () => {
  const navigate = useNavigate()
  const stores = useStores()
  return (
    <div className='store-dashboard'>
      <StoreDashBox 
        labelPrimary="Total" 
        labelSecondary="Active Stores" 
        labelValue={stores?.length} 
        onClick={e => navigate('list/')}
      />      
    </div>
  );
};

export default StoreDashboard;