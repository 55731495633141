import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from '../../utils/axios'

export const fetchVideos = createAsyncThunk('video/fetchVideos', async (data, thunkAPI) => {
    const result = await axiosInstance.get('videos/')
    return result.data
  }
)

export const createVideo = createAsyncThunk('videos/createVideo', async (data, thunkAPI) => {
    const { formData, onUploadProgress } = data
    const result = await axiosInstance.post('videos/', formData, {
      headers: { "Content-Type": "multipart/form-data", },
      onUploadProgress
    })
    return result.data
  }
)

export const deleteVideo = createAsyncThunk('video/deleteVideo', async (videoId, thunkAPI) => {
    await axiosInstance.delete(`videos/${videoId}/`)
    return videoId
  }
)

export const updateVideo = createAsyncThunk('video/updateVideo', async (data, thunkAPI) => {
    const { id, title, stores } = data  
    const result = await axiosInstance.patch(`videos/${id}/`, { stores, title })
    return result.data
  }
)

const videoSlice = createSlice({
  name: 'video',
  initialState: {
    videos: null,
  },
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(fetchVideos.fulfilled, (state, action) => {
      state.videos = action.payload
    })
    .addCase(updateVideo.fulfilled, (state, action) => {
      state.videos = state.videos.map(v => v.id === action.payload.id ? action.payload : v)
    })
    .addCase(deleteVideo.fulfilled, (state, action) => {
      state.videos = state.videos.filter(v => v.id !== action.payload)
    })
    .addCase(createVideo.fulfilled, (state, action) => {
      state.videos = state.videos.concat(action.payload)
    })
  }
})

export const { setSelectedVideoId } = videoSlice.actions
export default videoSlice.reducer

