export const transformStoreTodayData = (data) => {
  // incoming data has this format
  // [{count: 12, timestamp: 13232211}, ...]
  // -> {name: hourlyTimestamp, device1[0]: countN[0], deviceN[0]: countN[0] }

  // generate 24 hours timeline .
  const hourlyTimestamps = Array(24)
    .fill(0)
    .map((_, i) => i + 1);

  // separate the keys from the values
  const deviceIds = Object.keys(data);
  const deviceDatas = Object.values(data);

  // we have a  default prefered data and we using the data from the
  // server to populate the default data
  const transformedData = deviceDatas.map((deviceData) =>
    deviceData.reduce((acc, curr) => {
      const { timestamp, count } = curr;
      const dateObject = new Date(Number(timestamp) * 1_000);
      const hour = dateObject.getHours();
      return { ...acc, [hour]: Number(acc[hour] + count) };
    }, Object.fromEntries(hourlyTimestamps.map((e) => [e, 0.1])))
  );

  // console.log("transformed Data: ", transformedData);
  // transform the data to be usable for the chart
  // [{1: 22: 2: 10}, ...] => [{name: hourN, device1: device1Count, ... }]
  const _dataList = hourlyTimestamps.map((hour) => ({
    name: `${hour}`,
    ...Object.fromEntries(
      deviceIds.map((dev, k) => [dev, transformedData[k][hour]])
    ),
  }));

  return _dataList;
};

export const transformStoreWeekData = (data) => {
  // data is like the below format
  // {deviceId: {day7: count, day6: count, ...}, ...}
  // to be transformed to
  // {name: dayN, device1: device1Count, ... deviceN: deviceNcount}

  const deviceIds = Object.keys(data);
  const deviceDatas = Object.values(data);

  // exit early if there's no object to transform

  const daysNames = Object.keys(deviceDatas[0]);

  const transformedData = daysNames.map((dayName, index) => {
    return {
      name: daysNames[index],
      ...Object.fromEntries(
        deviceIds.map((devId, devIdx) => [devId, deviceDatas[devIdx][dayName]])
      ),
    };
  });

  console.log("Week transformed data: ", transformedData);
  return transformedData;
};

export const transformStoreMonthData = transformStoreWeekData;

// for the device transformations

export const transformDeviceWeekData = (data) => {
  // expected data {1th: count, 2th: count}
  // parsed :  [ {name: 1th, value: count}]
  return Object.entries(data).map(([dayName, count]) => ({
    name: dayName,
    value: count,
  }));
};

export const transformDeviceMonthData = transformDeviceWeekData;

export const transformDeviceDayData = (data) => {
  const hourlyTimestamps = Array(24)
    .fill(0)
    .map((_, i) => i + 1);

  const transformedDeviceData = data.reduce((acc, curr) => {
    const { timestamp, count } = curr;
    const dateObject = new Date(Number(timestamp) * 1_000);
    const hour = dateObject.getHours();
    return { ...acc, [hour]: Number(acc[hour] + count) };
  }, Object.fromEntries(hourlyTimestamps.map((e) => [e, 0.1])));

  const _dataList = Object.entries(transformedDeviceData).map(([h, c]) => ({
    name: h,
    value: c,
  }));
  return _dataList;
};
