import {useMemo, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import ClientStoreItem from '../../components/store/ClientStoreItem'
import TextInput from '../../components/fields/TextInput';
import SortButton from '../../components/SortButton';
import CheckBox from '../../components/fields/CheckBox';

import {useClient} from '../../hooks/client';
import {
  useStoresByIds,
  useVideoClientStores,
  useClientPhotoBoothStores,
  useClientWheelStores,
} from '../../hooks/store';

const SortBox = ({label}) => {
  return (
    <div className="sort-btn">
      <span>{label}</span>
      <SortButton />
    </div>
  )
}

const ClientStoresContainer = () => {
  const params = useParams()
  const navigate = useNavigate()
  // const client = useClient(params.clientId)
  const videoStores = useVideoClientStores(params.clientId)
  const photoStores = useClientPhotoBoothStores(params.clientId)
  const wheelStores = useClientWheelStores(params.clientId)
  const [searchFilter, setSearchFilter] = useState('')
  const [videoFilter, setVideoFilter] = useState(false)
  const [photoFilter, setPhotoFilter] = useState(false)
  const [wheelFilter, setWheelFilter] = useState(false)

  const filterActive = useMemo( 
    () => ( videoFilter || photoFilter || wheelFilter), 
    [videoFilter, photoFilter, wheelFilter]
  )

  // we have to momoize this , defintely 
  const constructedStoreData =  useMemo( () => {
    let stores = videoStores.reduce( (acc, store) => {
        // if store key not in acc , add entry 
        if(!acc.hasOwnProperty(store.id))
          // add 'video' as entry , we don't want dup entry 
          return {...acc, [store.id]: ['video']}
      }, {})

    stores = photoStores.reduce( (acc, store) => {
      if(!acc.hasOwnProperty(store.id)) 
        return {...acc, [store.id]: ['photo']}
      if(!acc[store.id].includes('photo'))
        return {...acc, [store.id]: [...acc[store.id], 'photo']}
    }, stores) // we used the result from the compute above as input for this one 

    stores = wheelStores.reduce( (acc, store) => {
      if(!acc.hasOwnProperty(store.id)) 
        return {...acc, [store.id]: ['wheel']}
      if(!acc[store.id].includes('wheel'))
        return {...acc, [store.id]: [...acc[store.id], 'wheel']}
    }, stores) // we used the result from the compute above as input for this one 

    return stores 

  }, [videoStores, photoStores, wheelStores])

  const clientStores = useStoresByIds(Object.keys(constructedStoreData))

  // gather the pattern first, we can memoize the below if need be
  const filterPattern = useMemo( () => {
    let _pattern = []
    if ( videoFilter ) _pattern.push('video') 
    if ( photoFilter ) _pattern.push('photo') 
    if ( wheelFilter ) _pattern.push('wheel') 
    return _pattern
  }, [videoFilter, photoFilter, wheelFilter])
 
  return (
    <div className='client-stores-container'>
      <div className="header">
        <div className="header__left">
          <span className='primary-text'>Stores</span>
          <span className='secondary-text'>Active Location</span>
        </div>
        <div className="header__right">
          <TextInput 
            placeholder="search" 
            value={searchFilter} 
            onChange={e => setSearchFilter(e.target.value)}
          />
          <span><ion-icon name="search-outline"></ion-icon></span>
        </div>
      </div>
      <div className="content">
        <div className="content__header">
          <div className="content__header-sort">
            
          <SortBox label="Store Name" />
          <SortBox label="City" />
          <SortBox label="State" />
          <SortBox label="Date" /> 
            
          </div>

          <div className="content__header-filter">
            <CheckBox checked={videoFilter} onChange={e => setVideoFilter(p => !p)}>Video </CheckBox>
            <CheckBox checked={photoFilter} onChange={e => setPhotoFilter(p => !p)}>Photobooth </CheckBox>
            <CheckBox checked={wheelFilter} onChange={e => setWheelFilter(p => !p)}>Game </CheckBox>
          </div>

        </div>
        <div className="content__body">
          {clientStores
            ?.filter(store => store.name.toLowerCase().includes(searchFilter.toLowerCase())) // this for search
            .filter(store => {   
              if (!filterActive) return true;
              // return true if array equal else return false 
              if((filterPattern.length === constructedStoreData[store.id].length) && 
                (filterPattern.every( p => constructedStoreData[store.id].includes(p)))) {
                return true
              }
              return false 
            })
            .map( store => (
              <ClientStoreItem
                key={store.id} 
                store={store} 
                properties={constructedStoreData[store.id]} 
                onClick={e => navigate(`/stores/${store.id}`)}
              />
          ))}
        </div>
      </div>
    </div>
  );
}
export default ClientStoresContainer;